import { ApolloError } from '@apollo/client';
import { defaultTo } from 'lodash';
import { useCurrentUserNameQuery } from '../../../../graphQL';

type UserInitials = [
  string,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

export const useUserInitials = (): UserInitials => {
  const { data, loading, error } = useCurrentUserNameQuery();

  const userName = defaultTo(data?.currentHubUser.preferredName, '');

  return [userName.charAt(0).toUpperCase(), { loading, error }];
};
