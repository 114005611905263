import React, { useEffect } from 'react';
import { Layout, Heading } from '../../components/core';
import { PageContent, PageFooter, PageLoading } from '../../components/page';
import { useOnDemandUserQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { OnDemandPageWrapper } from './components/OnDemandPageWrapper';
import { EncounterForm } from './forms/EncounterForm';
import { isDuringHoliday, isDuringServiceHours } from './utils/availability';

export const onDemandPreEncounterPage = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDuringServiceHours() || isDuringHoliday()) {
      navigate(getRoute('onDemandHome', {}));
    }
  }, []);

  const { data: patientData, loading } = useOnDemandUserQuery();

  if (loading) {
    return <PageLoading pageName="on demand pre encounter page" />;
  }

  const currentPhone = patientData?.currentHubUser.phone ?? '';

  return (
    <OnDemandPageWrapper testID="on-demand-pre-encounter-page">
      <PageContent>
        <Layout.VStack space={7}>
          <Heading.h2>Before we connect, please answer a few short questions</Heading.h2>

          <EncounterForm currentPhone={currentPhone} />
        </Layout.VStack>
        <PageFooter />
      </PageContent>
    </OnDemandPageWrapper>
  );
};
