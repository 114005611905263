import { getStylesheet } from '../../styles';
import { Layout, Text, Link } from '../core';

export const PageCrisis = (): JSX.Element => {
  return (
    <Layout.Box {...styles.warningBox}>
      <Layout.VStack space={4}>
        <Text.para bold>If you are experiencing a crisis:</Text.para>
        <Text.paraSmall bold>Emergency: Dial 911</Text.paraSmall>

        <Layout.Box>
          <Text.paraSmall bold>National Suicide Prevention Hotline</Text.paraSmall>
          <Text.paraSmall>1 (800) 273-8255</Text.paraSmall>
          <Link.caption to="https://suicidepreventionlifeline.org/chat/">
            Suicide Prevention Lifeline Web Chat
          </Link.caption>
        </Layout.Box>

        <Layout.Box>
          <Text.paraSmall bold>Crisis Text Line</Text.paraSmall>
          <Text.paraSmall>Text HOME to 741-741</Text.paraSmall>
        </Layout.Box>

        <Layout.Box>
          <Text.paraSmall bold>National Hopeline Network</Text.paraSmall>
          <Text.paraSmall>1 (800) 784-2433</Text.paraSmall>
        </Layout.Box>
      </Layout.VStack>
    </Layout.Box>
  );
};

const styles = getStylesheet({
  warningBox: {
    padding: 4,
    backgroundColor: 'warning.alpha.20:alpha.20',
    borderWidth: 1,
    borderColor: 'warning.300',
  },
});
