import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { useOldUserAuth } from '../hooks/useOldUserAuth';
import { useSsoNavigation } from '../hooks/useSsoNavigation';
import { OldIneligibleEmailModal } from '../OldIneligibleEmailModal';

export function OldLoginForm(): JSX.Element {
  const [showIneligibleModal, setShowIneligibleModal] = useState(false);
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const ssoNavigate = useSsoNavigation({
    onError: ssoNavError => {
      formContext.setError('submit', ssoNavError);
    },
  });

  const [authorizeUser, { loading }] = useOldUserAuth({
    onCompleted: ({ samlEntryPoint }): void => {
      ssoNavigate(samlEntryPoint);
    },
    onError: authError => {
      if (authError.type === 'no-single-sign-on') {
        setShowIneligibleModal(true);
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ email }): Promise<void> => {
      await authorizeUser({ email });
    })();
  };

  const onCloseSubmitError = (): void => {
    setShowIneligibleModal(false);
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={3}>
        <Layout.View>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}

          {showIneligibleModal && <OldIneligibleEmailModal onClose={onCloseSubmitError} />}
        </Layout.View>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-sso-login-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
}
