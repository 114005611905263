import { useEffect } from 'react';
import { PageError } from '../../components/page/PageError';
import { config } from '../../config';
import { getRoute, useNavigate } from '../../routes';
import { LaunchZoomScreen } from './components/LaunchZoomScreen';

export const OnDemandJoinCallPage = (): JSX.Element => {
  const { zoomContactCenterApiKey, zoomContactCenterEntryId } = config;
  const ZOOM_CONTACT_CENTER_SDK_URL =
    'https://us01ccistatic.zoom.us/us01cci/web-sdk/video-client.js';

  const navigate = useNavigate();

  const initiateZoomVideoClient = async (): Promise<void> => {
    const videoClient = new VideoClient({ apikey: zoomContactCenterApiKey });
    await videoClient.init({
      entryId: zoomContactCenterEntryId,
    });

    void videoClient.startVideo();

    videoClient.on('video-force-end', () => {
      // TODO: navigate to onDemandHome page is not working at the moment
      navigate(getRoute('onDemandHome', {}));
    });
  };

  useEffect(() => {
    // Add the script tag containing Zoom Contact Center SDK to the page
    const script = document.createElement('script');
    script.src = ZOOM_CONTACT_CENTER_SDK_URL;

    document.body.appendChild(script);

    script.onload = () => {
      void initiateZoomVideoClient();
    };

    script.onerror = () => {
      document.body.removeChild(script);
      return <PageError goBackTo={getRoute('home', {})} />;
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <LaunchZoomScreen />;
};
