import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CoachingStatus } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { useCoachingContext } from '../context/coachingContext';

export const useCoachingOnboardRedirectToDashboard = (): void => {
  const navigate = useNavigate();

  const { coachingStatus } = useCoachingContext();

  useEffect(() => {
    if (coachingStatus === undefined) {
      // The coaching status has not loaded yet.
      return;
    }

    if (coachingStatus === CoachingStatus.Active) {
      // Temporary for backwards compatibility. Will be removed in [sc-29271]
      return;
    }

    // If they are not onboarding, redirect them back to the coaching homepage.
    if (coachingStatus !== CoachingStatus.Onboarding) {
      navigate(getRoute('coaching', {}), { replace: true });
    }
  }, [coachingStatus]);
};
