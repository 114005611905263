import { fromPairs } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { PageLoading } from '../components/page';
import {
  ActivePortals,
  AllowedPortal,
  castToRule,
  Portal,
  Portals,
} from '../content/portal/portals';
import { useGetPortalsQuery, usePortalUpdatesSubscription } from '../graphQL';
import { useIsCurrentRoutePublic } from '../routes';

export type OnlyIfPortalIsActive = <A, F>(portal: AllowedPortal, allowed: A, fallback: F) => A | F;
export type PortalSwitch = <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F) => A | F;

type PortalContextType = {
  activePortals: ActivePortals;
  onlyIfPortalIsActive: OnlyIfPortalIsActive;
  portalsAreLoading: boolean;
  portalSwitch: PortalSwitch;
  refetchPortals: () => void;
};

const PortalContext = createContext<PortalContextType>({
  activePortals: {},
  onlyIfPortalIsActive: <A, F>(portal: AllowedPortal, allowed: A, fallback: F) => fallback,
  portalsAreLoading: true,
  portalSwitch: <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F) => fallback,
  refetchPortals: () => undefined,
});

export const PortalConsumer = PortalContext.Consumer;

const getActivePortalsFromList = (portalsList: Portal[]): ActivePortals =>
  fromPairs(portalsList.map(activePortal => [activePortal, true]));

export const PortalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const defaultPortals = {
    [Portals.Hub]: true,
  };

  const [activePortals, setActivePortals] = useState<ActivePortals>(defaultPortals);

  // Use this to determine whether or load the active portals.
  // Make sure that when the user logs in, the active portals are loaded.
  const isPublicRoute = useIsCurrentRoutePublic();

  const { loading: portalsAreLoading, refetch: refetchPortalsQuery } = useGetPortalsQuery({
    skip: isPublicRoute,
    onCompleted: ({ getPortals }) => {
      if (getPortals === undefined) {
        setActivePortals(defaultPortals);
        return;
      }

      setActivePortals(getActivePortalsFromList(getPortals));
    },
    onError: portalError => {
      if (portalError.message === 'LocationBlocked') {
        setActivePortals({
          [Portals.LocationBlocked]: true,
        });
        return;
      }

      // Make sure the default portals are set.
      setActivePortals(defaultPortals);
    },
  });

  usePortalUpdatesSubscription({
    onData: ({ data: { data } }) => {
      const newActivePortals = data?.portalUpdates;
      if (newActivePortals === undefined) {
        return;
      }

      setActivePortals(getActivePortalsFromList(newActivePortals));
    },
  });

  const refetchPortals = (): void => {
    void refetchPortalsQuery();
  };

  const onlyIfPortalIsActive = useCallback(
    <A, F>(allowedPortals: AllowedPortal, allowed: A, fallback: F): A | F => {
      const isAllowed = castToRule(allowedPortals).check(activePortals);
      return isAllowed ? allowed : fallback;
    },
    [activePortals],
  );

  const portalSwitch = useCallback(
    <A, F>(portalAndTo: Array<[AllowedPortal, A]>, fallback: F): A | F => {
      const foundAllowed = portalAndTo.find(([allowedPortals]) =>
        castToRule(allowedPortals).check(activePortals),
      );

      if (foundAllowed === undefined) {
        return fallback;
      }

      return foundAllowed[1];
    },
    [activePortals],
  );

  const providerValue: PortalContextType = {
    activePortals,
    onlyIfPortalIsActive,
    portalsAreLoading,
    portalSwitch,
    refetchPortals,
  };

  return (
    <PortalContext.Provider value={providerValue}>
      {portalsAreLoading ? <PageLoading pageName="app" /> : children}
    </PortalContext.Provider>
  );
};

export const usePortalContext = (): PortalContextType => {
  return useContext(PortalContext);
};
