import { ApolloError } from '@apollo/client';
import { useCurrentUserImpersonatedQuery } from '../../../../graphQL';

type UserImpersonatedReturn = [
  {
    isImpersonated: boolean;
    impersonatingId: string | undefined;
  },
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

export const useUserImpersonated = (): UserImpersonatedReturn => {
  const { data, loading, error } = useCurrentUserImpersonatedQuery();

  const impersonatingId = data?.currentHubUser.impersonatingId ?? undefined;

  return [
    {
      isImpersonated: Boolean(impersonatingId),
      impersonatingId,
    },
    { loading, error },
  ];
};
