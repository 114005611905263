import { useEffect } from 'react';
import { Layout } from '../../components/core';
import { PageLoading } from '../../components/page';
import { InputMonitorWarning } from '../../content/assessment/components/InputMonitorWarning';
import { useFindCareUserQuery } from '../../graphQL';
import { getRoute, useLocation, useNavigate } from '../../routes';
import { ConfirmContactForm } from '../onDemand/forms/ConfirmContactForm';
import { FindCarePageHeader } from './components/FindCarePageHeader';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { useFindCareContext } from './context/findCareContext';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export function FindCareConfirmInfoPage(): JSX.Element {
  useFindCareRedirect();

  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { data: patientData, loading } = useFindCareUserQuery();

  const { hasAllRequiredFields } = useFindCareContext();

  useEffect(() => {
    // Don't redirect if the user navigates back to this page
    if (locationState?.redirect === false) {
      return;
    }

    // Redirect to the demographic screen if the user has already filled out the form
    if (hasAllRequiredFields) {
      navigate(getRoute('findCareDemographics', {}));
    }
  }, [hasAllRequiredFields, locationState]);

  if (loading) {
    return <PageLoading pageName="find care confirm info" />;
  }

  return (
    <FindCarePageWrapper testID="find-care-confirm-info-page">
      <Layout.VStack space={7}>
        <FindCarePageHeader
          title="Confirm your information"
          undertext="For your safety, we want to make sure your contact info is up-to-date."
        />

        {patientData !== undefined && (
          <ConfirmContactForm
            hubUser={patientData.currentHubUser}
            nextRoute="findCareDemographics"
          />
        )}

        <InputMonitorWarning />
      </Layout.VStack>
    </FindCarePageWrapper>
  );
}
