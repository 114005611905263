import React, { useEffect } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { useSkillContext } from '../../contexts';
import { useHomePageUserQuery } from '../../graphQL';
import { getRoute, RouteParams, useNavigate, useParams } from '../../routes';
import { TourSlides } from './TourSlides';

type TourParams = RouteParams<'tour'>;

export const TourPage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<TourParams>();
  const { data: orgData, loading: orgLoading } = useHomePageUserQuery();

  const { skill, skillState, slideCount, slideNumber } = useSkillContext();

  const refetchAll = (): void => {
    skillState.refetch();
  };

  useEffect(() => {
    // If the slide set in the URL wasn't a valid number, then redirect again to the first slide.
    if (slideNumber !== Number(params.slide)) {
      navigate(
        getRoute('tour', {
          slide: String(slideNumber),
        }),
      );
      return;
    }

    // If the slide set in the URL is larger than the slideCount, then redirect to the last slide.
    if (slideCount !== undefined && slideNumber > 1 && slideNumber > slideCount) {
      navigate(
        getRoute('tour', {
          slide: String(slideCount),
        }),
      );
    }
  }, [slideNumber, skill]);

  if (!skillState.loaded || orgLoading) {
    return <PageLoading pageName="tour" />;
  }
  if (skillState.error) {
    return <PageError goBackTo={getRoute('home', {})} onRefreshPress={refetchAll} />;
  }
  if (!skill) {
    return <PageError goBackTo={getRoute('home', {})} onRefreshPress={refetchAll} />;
  }

  const orgName = orgData?.currentHubUser?.organization?.name ?? '';

  return <TourSlides orgName={orgName} />;
};
