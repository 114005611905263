import { defaultTo } from 'lodash';
import { JSX, useEffect } from 'react';
import { Heading, Layout } from '../../components/core';
import { PageContent } from '../../components/page';
import { useSearchParams } from '../../routes';
import { parseSsoErrorNumberToType, AuthError } from './components/AuthError';
import { useLoginRedirect } from './hooks/useLoginRedirect';
import { useSsoLogin } from './hooks/useSsoLogin';

const SsoLoadingPage = (): JSX.Element => (
  <PageContent testID="sso-response-loading-page">
    <Layout.Center>
      <Heading.h4>Logging you in...</Heading.h4>
    </Layout.Center>
  </PageContent>
);

export const AuthenticationSsoResponsePage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const loginRedirect = useLoginRedirect();

  const [login, { loading: loginLoading, error: loginError }] = useSsoLogin({
    onCompleted: () => {
      // Eventually we need to check for something other than source=hub to determine onDemand,
      // but for now onDemand is the only thing using source=hub, so this is how it works.
      const redirectTo = source === 'hub' ? 'onDemand' : undefined;

      void loginRedirect(redirectTo);
    },
  });

  const redirectError = Number(defaultTo(searchParams.get('error'), 0));
  const tokenId = defaultTo(searchParams.get('tokenId'), '');
  const oneTimeToken = defaultTo(searchParams.get('token'), '');
  const source = defaultTo(searchParams.get('source'), '');
  const externalRedirect = defaultTo(searchParams.get('external_redirect'), '');

  useEffect(() => {
    void login({ tokenId, oneTimeToken });
  }, []);

  if (redirectError > 0) {
    return <AuthError errorType={parseSsoErrorNumberToType(redirectError)} />;
  }

  if (!tokenId || !oneTimeToken) {
    return <AuthError errorType="catchAll" />;
  }

  if (!loginLoading && loginError !== undefined) {
    // Something went wrong with the login-by-token process.
    return <AuthError errorType="catchAll" />;
  }

  if (externalRedirect) {
    return <AuthError errorType="toPatientPortal" />;
  }

  // If loading is false, the user should be redirected shortly, so just continue showing loading page.
  return <SsoLoadingPage />;
};
