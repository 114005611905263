import * as ExpoLinking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';
import { config } from '../config';
import { getRoute, Location } from '../routes';

export const getLoginUrl = (location: Location): string => {
  const to = `${location.pathname}${location.search}`;

  let search = {};
  if (location.pathname === '/') {
    // Any search params sent to the start page should be directly appended to the login url as well.
    const searchParams = new URLSearchParams(location.search);
    search = Object.fromEntries(searchParams.entries());
  }

  return getRoute('login', {}, { ...search, to });
};

export type MobilePlatforms = Extract<Platform['OS'], 'android' | 'ios'>;

export const getMobileNavigationStrategy = async (
  samlEntryPoint: string,
  platform: MobilePlatforms,
): Promise<string> => {
  // Android requires specific IP logic for local development
  // TODO: Use expo-network to get the local IP instead of using the env variable
  const entryPoint =
    platform === 'android' ? samlEntryPoint.replace('localhost', config.ipAddress) : samlEntryPoint;
  // Android requires a redirect URL for deep linking to work
  const redirectUrl = platform === 'android' ? ExpoLinking.createURL('') : undefined;

  // Android and iOS open a web browser to complete the SSO flow
  const authResult: WebBrowser.WebBrowserAuthSessionResult = await WebBrowser.openAuthSessionAsync(
    entryPoint,
    redirectUrl,
  );

  // Following successful SSO / IDP flow, the user returns to the app
  // and we verify the SSO response has the correct params
  // and redirect them to the loginSsoResponse page where they will be logged in
  if (isWebBrowserRedirectResult(authResult)) {
    const params = new URLSearchParams(authResult.url);

    const tokenId = params.get('tokenId') ?? '';
    const token = params.get('token') ?? '';

    return getRoute(
      'loginSsoResponse',
      {},
      {
        tokenId,
        token,
      },
    );
    // If they don't have the correct params, we send them to the SSO response page
    // where they will be shown an error
  }
  return getRoute('loginSsoResponse', {});
};

const isWebBrowserRedirectResult = (
  result: WebBrowser.WebBrowserAuthSessionResult,
): result is WebBrowser.WebBrowserRedirectResult => {
  return result.type === 'success' && result.url !== undefined;
};
