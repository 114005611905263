import { PageLoading } from '../../components/page';
import { useHomePageUserQuery } from '../../graphQL';
import { getRoute, useCurrentRouteSearch, useNavigate } from '../../routes';

export const StartPage = (): JSX.Element => {
  const navigate = useNavigate();
  const routeSearch = useCurrentRouteSearch();

  // Check to see if the user is logged in by making the same call the homepage does.
  // If the user is logged out, the unauthorized error will redirect them to the login page.
  useHomePageUserQuery({
    onCompleted: () => {
      // The user is logged in, so take them to the home page.
      navigate(getRoute('home', {}, routeSearch), {
        replace: true,
      });
    },
  });

  return <PageLoading pageName="app" />;
};
