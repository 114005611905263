import { useAllPrismicDocumentsByIDs, usePrismicDocumentsByTag } from '@prismicio/react';
import { compact, isEmpty } from 'lodash';
import { ModuleDocument } from '../../module/ModuleTypes';
import { ViewDocument } from '../ViewTypes';

type ViewResponse = [
  {
    view: ViewDocument | undefined;
    modulesById: Record<string, ModuleDocument>;
  },
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

const viewIdsByTag = {
  collections: 'CollectionView',
  topics: 'TopicView',
} as const;

export const useViewByType = (viewName: keyof typeof viewIdsByTag): ViewResponse => {
  const [view, { error: viewError, state: viewState }] = usePrismicDocumentsByTag<ViewDocument>(
    viewIdsByTag[viewName],
  );

  const firstView = view?.results[0];

  const moduleIds = compact(
    firstView?.data.slices.flatMap(slice =>
      slice.items.map(({ module }) => ('id' in module ? module.id : undefined)),
    ),
  );

  const [modules, { error: moduleError, state: moduleState }] =
    useAllPrismicDocumentsByIDs<ModuleDocument>(moduleIds, { skip: isEmpty(moduleIds) });

  const emptyModulesById: Record<string, ModuleDocument> = {};
  const modulesById =
    modules?.reduce((acc, module) => {
      acc[module.id] = module;
      return acc;
    }, emptyModulesById) ?? emptyModulesById;

  return [
    { view: firstView, modulesById },
    {
      idle: viewState === 'idle' && moduleState === 'idle',
      loading: viewState === 'loading' || moduleState === 'loading',
      loaded: viewState === 'loaded' && moduleState === 'loaded',
      error: viewError ?? moduleError,
    },
  ];
};
