import { setupApollo, SetupApolloProps, SetupApolloResponse } from './apolloSetup';
import { useSetupFonts } from './fonts';
import { heapInitialize } from './heap';
import { setupPolyfill } from './polyfillSetup';

import './sentry';

export { NativeBaseProvider } from './nativeBase';
export { PrismicProvider } from './prismic';

type PluginsResponse = {
  apolloClient: SetupApolloResponse;
};

type SetupAppPluginsProps = SetupApolloProps;

export function setupAppPlugins({ ...apolloProps }: SetupAppPluginsProps): PluginsResponse {
  heapInitialize();

  setupPolyfill();

  const apolloClient = setupApollo(apolloProps);

  return { apolloClient };
}

export function useSetup(): { isReady: boolean } {
  const { areFontsLoaded } = useSetupFonts();

  return { isReady: areFontsLoaded };
}
