import { JSX } from 'react';
import { Link, StatusFeedback } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';

export const BadSsoError = (): JSX.Element => {
  return (
    <StatusFeedback.danger Icon={IconAlert} testID="button-login-bad-sso-error">
      We're having an issue connecting to your institution's SSO. Please contact us at{' '}
      <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
        hi@mantrahealth.com
      </Link.paraSmall>
      .
    </StatusFeedback.danger>
  );
};
