import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { SignupErrorModal, SignupErrorType } from '../components/SignupErrorModal';

type SignupEmailFormProps = { onSuccess: (email: string) => void };

export const SignupEmailForm = ({ onSuccess }: SignupEmailFormProps): JSX.Element => {
  const [signupErrorType, setSignupErrorType] = useState<SignupErrorType | undefined>();
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const userEmail = watch('email');

  const loading = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validateEmailForSignup = (props: { email: string }): void => {
    onSuccess(userEmail);
  };
  // const [validateEmailForSignup, { loading }] = useValidateEmailForSignupMutation({
  //   onCompleted: () => {
  //     onSuccess(userEmail);
  //   },
  //   onError: authError => {
  //     // Some of the errors should show the signup error modal.
  //     // setSignupErrorType('unavailable');
  //
  //     // And other errors should show a submit error.
  //     // formContext.setError('submit', authError);
  //   },
  // });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ email }): Promise<void> => {
      await validateEmailForSignup({ email });
    })();
  };

  const onErrorClose = (): void => {
    setSignupErrorType(undefined);
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.View>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}

          {signupErrorType !== undefined && (
            <SignupErrorModal errorType={signupErrorType} onClose={onErrorClose} />
          )}
        </Layout.View>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-signup-email-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
