import { useToast } from 'native-base';
import { useHeapContext } from '../../../../contexts/heapContext';
import { useLogoutHubMutation } from '../../../../graphQL';
import { removeMobileToken, removeRedirectUrl } from '../../../../utils/authenticationStore';
import { useDisableDevice } from './useDisableDevice';

type LogoutResponse = [() => Promise<void>];

type LogoutHookOptions = {
  onCompleted: (output: boolean) => void;
};

export function useLogout({ onCompleted }: LogoutHookOptions): LogoutResponse {
  const toast = useToast();
  const { setIsHeapUserRegistered } = useHeapContext();
  const { asyncDisableDevice } = useDisableDevice();

  const [logoutHubMutation] = useLogoutHubMutation({
    onCompleted: data => {
      const loggedOutRes = data.logoutHub;
      if (loggedOutRes) {
        setIsHeapUserRegistered(false);
      }
      onCompleted(loggedOutRes);
    },
    onError: () => {
      toast.show({
        description: 'Could not log out',
      });
    },
  });

  const logoutMutation = async (): Promise<void> => {
    await asyncDisableDevice();
    await logoutHubMutation();
    await removeRedirectUrl();
    await removeMobileToken();
  };

  return [logoutMutation];
}
