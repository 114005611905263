import { JSX, Path, Svg, Circle } from '../../../components/icons';

export const ImageCoachingKickoffSchedule = (): JSX.Element => {
  return (
    <Svg
      title="A finger clicking an image"
      width="162"
      height="120"
      viewBox="0 0 162 120"
      fill="none"
    >
      <Circle cx="79.8953" cy="62.9692" r="55.2362" fill="#E0E7FF" />
      <Path
        d="M71.976 46.1874L11.9595 58.041C10.283 58.3751 8.65582 57.28 8.32793 55.6035L0.415221 15.5451C0.0811436 13.8685 1.17629 12.2415 2.85286 11.9136L62.8691 0.0600011C64.5457 -0.274076 66.1728 0.820957 66.5007 2.49753L74.4135 42.5559C74.7475 44.2325 73.6525 45.8595 71.976 46.1874Z"
        fill="#FAEAD3"
      />
      <Path
        d="M67.5524 7.82426L1.46692 20.878L0.415221 15.5513C0.0811436 13.8748 1.17629 12.2477 2.85286 11.9198L62.8691 0.0662343C64.5457 -0.267843 66.1728 0.82719 66.5007 2.50376L67.5524 7.82426Z"
        fill="#A2CAC2"
      />
      <Path
        d="M62.9497 4.39092C63.1538 5.43646 62.4795 6.44488 61.434 6.65523C60.3885 6.85938 59.3799 6.18503 59.1696 5.1395C58.9654 4.09396 59.6399 3.08555 60.6854 2.8752C61.731 2.67104 62.7455 3.34538 62.9497 4.39092Z"
        fill="#FAEAD3"
      />
      <Path
        d="M56.0022 5.76409C56.2063 6.80962 55.532 7.81805 54.4865 8.02839C53.4409 8.23255 52.4324 7.5582 52.2221 6.51266C52.0179 5.46712 52.6924 4.45871 53.7379 4.25455C54.7835 4.03802 55.798 4.71855 56.0022 5.76409Z"
        fill="#FAEAD3"
      />
      <Path
        d="M48.093 32.7293C48.9388 28.0805 45.8559 23.6261 41.207 22.7803C36.5582 21.9344 32.1038 25.0174 31.258 29.6663C30.4121 34.3151 33.4951 38.7695 38.1439 39.6153C42.7928 40.4612 47.2471 37.3782 48.093 32.7293Z"
        fill="#A2CAC2"
      />
      <Path
        d="M27.4444 40.4091C24.9264 40.4091 23.4601 39.8337 23.0704 38.683C22.1671 36.029 27.9948 32.8552 30.5499 31.6117C30.8592 31.4633 31.2243 31.5932 31.379 31.8963C31.5274 32.2056 31.4037 32.5768 31.0944 32.7253C25.6316 35.3856 23.9983 37.5447 24.2458 38.2809C24.4252 38.8068 25.7307 39.3388 28.8302 39.1161C31.7935 38.8996 35.5487 38.0582 39.4092 36.7404C43.2696 35.4227 46.759 33.7956 49.2336 32.1562C51.8196 30.4425 52.531 29.2175 52.3515 28.6917C52.1721 28.1658 50.8668 27.6337 47.7611 27.8565C47.427 27.8812 47.1239 27.6275 47.0991 27.2873C47.0744 26.947 47.3281 26.6501 47.6684 26.6253C51.0834 26.3779 53.0569 26.9347 53.5209 28.2957C53.9849 29.6506 52.7723 31.2962 49.9141 33.1893C47.3528 34.8845 43.7646 36.561 39.8052 37.9159C35.8458 39.2646 31.9791 40.1307 28.9167 40.3534C28.3847 40.3906 27.896 40.4091 27.4444 40.4091Z"
        fill="#35405F"
      />
      <Path
        d="M32.6227 39.2151C33.0063 41.1515 31.7442 43.0322 29.8077 43.4158C27.8713 43.7994 25.9906 42.5373 25.607 40.6009C25.2234 38.6645 26.4855 36.7837 28.4219 36.4002C30.3584 36.0166 32.2391 37.2787 32.6227 39.2151Z"
        fill="#A2CAC2"
      />
      <Path
        d="M93.8397 75.407C93.8397 75.407 82.7038 71.4909 79.0042 70.2659C74.3147 68.7131 70.8997 70.235 69.6377 71.7754C68.2766 73.4335 67.3795 76.9041 70.9244 79.9232C74.4693 82.9423 97.1062 100.481 103.942 104.886C112.69 110.522 123.016 111.716 123.016 111.716L129.264 120C129.264 120 152.217 109.155 161.645 96.4044C161.645 96.4044 137.987 56.612 133.743 51.2049C129.499 45.7978 124.736 42.6859 118.295 40.7248C111.855 38.7636 104.493 42.6303 104.493 42.6303C104.493 42.6303 96.1906 41.9497 84.7639 45.9339C75.1128 49.2994 70.6337 52.6464 68.7097 55.8511C66.9465 58.7959 69.3283 64.6484 77.2781 63.3987C85.2279 62.1491 90.0783 61.153 90.0783 61.153L93.8397 75.407Z"
        fill="#F67E6A"
      />
      <Path
        d="M94.6808 75.7901C94.6808 75.7901 66.7606 50.2889 62.0649 45.3767C57.6539 40.7615 57.8147 35.5152 60.2275 33.2014C63.1167 30.4298 66.6616 30.8876 69.495 33.1953C72.3285 35.5029 105.656 62.6621 105.656 62.6621L94.6808 75.7901Z"
        fill="#F67E6A"
      />
      <Path
        d="M93.4065 75.8153C95.8254 77.1083 98.263 78.3766 100.707 79.6201C101.412 79.9789 102.043 78.9148 101.332 78.5498C98.8817 77.3063 96.4503 76.0381 94.0313 74.745C93.326 74.3677 92.7012 75.438 93.4065 75.8153Z"
        fill="#35405F"
      />
      <Path
        d="M104.932 43.3789C111.725 45.4947 117.435 49.3057 122.032 54.7375C122.545 55.3438 123.418 54.4653 122.904 53.8652C118.184 48.2787 112.245 44.3625 105.26 42.191C104.499 41.9498 104.171 43.1438 104.932 43.3789Z"
        fill="#35405F"
      />
      <Path
        d="M59.5412 35.0762C61.8921 37.3591 65.2143 41.2257 68.7654 41.3928C71.3143 41.5103 73.2817 39.3821 73.3683 36.9198C73.3931 36.1218 72.1558 36.1218 72.131 36.9198C71.9887 41.0711 67.7509 40.2854 65.4556 38.615C63.6491 37.3034 62.0158 35.7567 60.4196 34.1977C59.8505 33.6471 58.972 34.5194 59.5412 35.0762Z"
        fill="#35405F"
      />
      <Path
        d="M70.5034 72.1649C74.0174 74.1941 80.3896 78.6918 76.3683 83.3874C75.8548 83.9875 76.7272 84.866 77.2407 84.2597C81.8312 78.8959 75.4774 73.6064 71.1221 71.0946C70.4415 70.6987 69.8167 71.7689 70.5034 72.1649Z"
        fill="#35405F"
      />
      <Path
        d="M70.2312 54.7682C73.2132 57.447 76.1518 60.1691 79.0534 62.9345C79.6287 63.4851 80.5072 62.6128 79.9256 62.0622C77.0241 59.2968 74.0854 56.5747 71.1035 53.8959C70.5158 53.3638 69.6373 54.2361 70.2312 54.7682Z"
        fill="#35405F"
      />
      <Path
        d="M85.7909 46.6824C91.7672 51.3533 97.7434 56.0242 103.72 60.6951C104.338 61.1777 105.223 60.3116 104.592 59.8228C98.6159 55.1519 92.6396 50.481 86.6633 45.8101C86.0447 45.3276 85.1661 46.1937 85.7909 46.6824Z"
        fill="#35405F"
      />
      <Path
        d="M85.8645 30.7207C82.765 31.0857 79.6717 31.4507 76.5722 31.8219C75.7927 31.9147 75.7803 33.152 76.5722 33.0592C79.6717 32.6942 82.765 32.3292 85.8645 31.958C86.6502 31.8652 86.6564 30.6279 85.8645 30.7207Z"
        fill="#35405F"
      />
      <Path
        d="M55.2347 16.7203C56.8185 19.4053 58.396 22.0964 59.9798 24.7814C60.3819 25.4681 61.4522 24.8433 61.0501 24.1566C59.4663 21.4716 57.8888 18.7804 56.305 16.0954C55.8967 15.4087 54.8264 16.0336 55.2347 16.7203Z"
        fill="#35405F"
      />
      <Path
        d="M74.1349 15.978C72.6996 18.7496 71.2642 21.515 69.8289 24.2866C69.4639 24.9919 70.5281 25.6168 70.8993 24.9115C72.3346 22.1399 73.7699 19.3745 75.2052 16.6029C75.5702 15.8976 74.5061 15.2727 74.1349 15.978Z"
        fill="#35405F"
      />
    </Svg>
  );
};
