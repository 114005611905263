import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout, Link } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { getRoute } from '../../../routes';
import { BadSsoError } from '../components/BadSsoError';
import { RateLimitError } from '../components/RateLimitError';
import { useLoginRedirect } from '../hooks/useLoginRedirect';
import { ErrorTypesLoginUser, useLoginUser } from '../hooks/useLoginUser';
import { useSsoNavigation } from '../hooks/useSsoNavigation';

export const LoginForm = (): JSX.Element => {
  const loginRedirect = useLoginRedirect();
  const ssoNavigate = useSsoNavigation({
    onError: ssoNavError => {
      formContext.setError('submit', ssoNavError);
    },
  });

  const [showRateLimitError, setShowRateLimitError] = useState(false);
  const [showBadSsoError, setShowBadSsoError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formContext = useForm({
    defaultValues: {
      email: '',
      password: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const [loginUser, { loading }] = useLoginUser({
    onCompleted: ({ loginSuccess, samlEntryPoint }): void => {
      if (loginSuccess) {
        void loginRedirect();
        return;
      }

      if (samlEntryPoint !== undefined && samlEntryPoint !== '') {
        ssoNavigate(samlEntryPoint);
        return;
      }

      setShowPassword(true);
    },
    onError: authError => {
      if (authError.type === ErrorTypesLoginUser.RateLimit) {
        setShowRateLimitError(true);
        return;
      }
      if (authError.type === ErrorTypesLoginUser.NoSingleSignOn) {
        setShowBadSsoError(true);
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();
    setShowBadSsoError(false);

    void handleSubmit(async ({ email, password }): Promise<void> => {
      await loginUser({ email, password: password || undefined });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={!showPassword ? submitForm : undefined}
          />

          {showPassword && (
            <FormInput
              label="Password"
              name="password"
              placeholder="Password"
              type="password"
              autoFocus
              isRequired
              control={control}
              error={errors.password}
              onSubmitEditing={submitForm}
            />
          )}

          {showPassword && (
            <Link.paraSmall bold to={getRoute('loginResetPassword', {})}>
              Forgot Password?
            </Link.paraSmall>
          )}

          {showRateLimitError && <RateLimitError />}
          {showBadSsoError && <BadSsoError />}

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-sso-login-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
