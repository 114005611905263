import { useEffect } from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageError, PageLoading } from '../../components/page';
import { useOnDemandUserQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { CrisisSupport } from './components/CrisisSupport';
import { OnDemandPageWrapper } from './components/OnDemandPageWrapper';
import { ConfirmContactForm } from './forms/ConfirmContactForm';
import { isDuringHoliday, isDuringServiceHours } from './utils/availability';

export function OnDemandConfirmInfoPage(): JSX.Element {
  const { data: patientData, loading, error, refetch } = useOnDemandUserQuery();

  const hasPhoneNumber = Boolean(patientData?.currentHubUser?.phone);
  const hasBirthDate = Boolean(patientData?.currentHubUser?.birthDate);
  const hasFirstName = Boolean(patientData?.currentHubUser?.firstName);
  const hasLastName = Boolean(patientData?.currentHubUser?.lastName);

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the encounter form if the user has already filled out the form
    if (hasFirstName && hasLastName && hasPhoneNumber && hasBirthDate) {
      navigate(getRoute('onDemandPreEncounterForm', {}));
    }
  }, [hasFirstName, hasLastName, hasPhoneNumber, hasBirthDate]);

  useEffect(() => {
    if (!isDuringServiceHours() || isDuringHoliday()) {
      navigate(getRoute('onDemandHome', {}));
    }
  }, []);

  if (loading) {
    return <PageLoading pageName="on demand confirm info" />;
  }

  if (error) {
    return <PageError onRefreshPress={refetch} showContact />;
  }

  return (
    <OnDemandPageWrapper testID="on-demand-confirm-info-page">
      <Layout.VStack space={4}>
        <Heading.h2>Confirm your information</Heading.h2>
        <Layout.VStack space={8}>
          <Text.para>
            For your safety, we want to make sure your contact info is up-to-date.
          </Text.para>

          {patientData !== undefined && (
            <ConfirmContactForm
              hubUser={patientData.currentHubUser}
              nextRoute="onDemandPreEncounterForm"
            />
          )}
        </Layout.VStack>

        <CrisisSupport />
      </Layout.VStack>
    </OnDemandPageWrapper>
  );
}
