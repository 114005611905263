import { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';

type ResetPasswordFormProps = {
  onEmailSent: () => void;
};

export const ResetPasswordForm = ({ onEmailSent }: ResetPasswordFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const loading = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendPasswordReset = (props: { email: string }): void => {
    onEmailSent();
  };
  // const [sendPasswordReset, { loading }] = useResendActivationEmailMutation({
  //   onCompleted: ({ sendPasswordResetEmail }): void => {},
  //   onError: authError => {
  //     if (isRateLimited(authError)) {
  //       // TODO: What message should we show here?
  //       formContext.setError(
  //         'submit',
  //         new Error('Your account has been locked. Please wait 15 minutes before trying again.'),
  //       );
  //       return;
  //     }
  //
  //     formContext.setError('submit', authError);
  //   },
  // });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ email }): Promise<void> => {
      await sendPasswordReset({ email });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.View>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}
        </Layout.View>

        <Layout.VStack>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-reset-password-submit"
            onPress={submitForm}
          >
            Send
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
