import { JSX } from 'react';
import { Link, StatusFeedback } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';
import { getRoute } from '../../../routes';

export const RateLimitError = (): JSX.Element => {
  return (
    <StatusFeedback.danger Icon={IconAlert} testID="button-login-rate-limit-error">
      Your account has been locked due to too many unsuccessful login attempts. Please wait 15
      minutes or{' '}
      <Link.paraSmall bold to={getRoute('loginResetPassword', {})}>
        reset your password
      </Link.paraSmall>{' '}
      to log in.
    </StatusFeedback.danger>
  );
};
