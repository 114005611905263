import { useParams } from 'react-router-native';
import { PageError, PageLoading } from '../../components/page';
import { RouteParams } from '../../routes';
import { useCoachingOnboardRedirectToDashboard } from '../coaching/hooks/useCoachingOnboardRedirectToDashboard';
import { CoachingAssessmentSlides } from './components/CoachingAssessmentSlides';
import { CoachingAssessmentProvider } from './contexts/coachingAssessmentContext';
import { CoachingAssessmentSlideProvider } from './contexts/coachingAssessmentSlideContext';
import { useCoachingAssessment } from './hooks/useCoachingAssessment';

type CoachingAssessmentPageParams = RouteParams<'coachingAssessment'>;

export const CoachingAssessmentPage = (): JSX.Element => {
  const { assessmentType } = useParams<CoachingAssessmentPageParams>();

  // Redirects the user to the coaching home page if they are already in coaching.
  useCoachingOnboardRedirectToDashboard();

  const { assessments, type, loading, error } = useCoachingAssessment({
    assessmentType,
  });

  if (loading) {
    return <PageLoading pageName="coaching assessment" />;
  }
  if (error !== undefined || type === undefined) {
    return <PageError />;
  }

  const questionnaires = assessments?.questionnaires;

  return (
    <CoachingAssessmentSlideProvider>
      <CoachingAssessmentProvider>
        <CoachingAssessmentSlides assessmentType={type} questionnaires={questionnaires} />
      </CoachingAssessmentProvider>
    </CoachingAssessmentSlideProvider>
  );
};
