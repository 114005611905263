import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import React from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { InputProps, Input } from '../core/Input';
import { FormErrorInput } from './FormErrorInput';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum InputErrorTypes {
  Pattern = 'pattern', // from rules.pattern
  Required = 'required', // from isRequired or rules.required
}

type FormInputProps<TFieldValues extends FieldValues> = InputProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  FormLabelProps & {
    error?: FieldErrors<TFieldValues>[string];
  };

export function FormInput<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...inputProps
}: FormInputProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(inputProps.isRequired),
      message: 'This field is required',
    }),
  };

  const isRequired = inputProps.isRequired ?? false;

  return (
    <FormControl isInvalid={Boolean(error?.type)} onLayout={onLayout}>
      <FormLabel label={label} necessityIndicator={necessityIndicator} isRequired={isRequired} />

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <Input
            {...inputProps}
            isInvalid={inputProps.isInvalid ?? Boolean(error)}
            onBlur={onBlur}
            onChangeText={onChange}
            ref={ref}
            value={value}
          />
        )}
      />

      {error && <FormErrorInput>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
