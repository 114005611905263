import React from 'react';
import { PageError, PageLoading } from '../../components/page';
import { Questionnaire, useFindCareStartQuery } from '../../graphQL';
import { useParams } from '../../routes';
import { FindCareAssessmentSlides } from './components/FindCareAssessmentSlides';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { FindCareSlideProvider } from './context/findCareSlideContext';

const mentalHealthHistory = 'mental-health-history';
export const questionnaireKeyMapping: Record<string, string> = {
  'mental-health-history': mentalHealthHistory,
  phq: 'phq8',
  gad: 'gad7',
};

export const FindCareAssessmentsPage = (): JSX.Element => {
  const { assessmentType } = useParams();

  const questionnaireKey =
    questionnaireKeyMapping[assessmentType ?? mentalHealthHistory] ?? mentalHealthHistory;
  const { data: assessmentData, loading } = useFindCareStartQuery({
    variables: { questionnaireKey },
  });

  const questionnaire = assessmentData?.findCareStart?.questionnaire;
  const onDemandEnabled = assessmentData?.findCareStart?.onDemandEnabled ?? false;

  if (loading) {
    return <PageLoading pageName="find care assessment" />;
  }

  if (assessmentType === undefined || questionnaire === undefined) {
    return <PageError />;
  }

  return (
    <FindCareSlideProvider>
      <FindCarePageWrapper testID="find-care-assessments-page" showGap={false}>
        <FindCareAssessmentSlides
          questionnaire={questionnaire as Questionnaire}
          assessmentType={assessmentType}
          onDemandEnabled={onDemandEnabled}
        />
      </FindCarePageWrapper>
    </FindCareSlideProvider>
  );
};
