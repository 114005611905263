import { Layout, StatusFeedback, Text } from '../../components/core';
import { PageContent, PageCrisis } from '../../components/page';

export const BlockedPage = (): JSX.Element => {
  return (
    <PageContent>
      <Layout.VStack space={4}>
        <StatusFeedback.danger testID="invalid-location">
          <Text.para bold>Invalid Location: </Text.para>
          <Text.paraSmall>
            The Mantra Care Hub is only available to students inside the U.S. Please contact your
            school’s counseling center for support.
          </Text.paraSmall>
        </StatusFeedback.danger>
        <PageCrisis />
      </Layout.VStack>
    </PageContent>
  );
};
