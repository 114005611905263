import { useAllPrismicDocumentsByIDs, usePrismicDocumentsByTag } from '@prismicio/react';
import { chain, isEmpty } from 'lodash';
import { useReload } from '../../../utils/useReload';
import { SkillDocument } from '../../skill/SkillTypes';
import { IntroViewSkillsSlice, ViewDocument } from '../ViewTypes';

type ViewResponse = [
  {
    view: ViewDocument | undefined;
    items: Array<{ skillId: string; sectionId: string; moduleId: string }> | undefined;
    skillsById: Record<string, SkillDocument>;
  },
  {
    idle: boolean;
    loading: boolean;
    loaded: boolean;
    error?: Error;
    refetch: () => void;
  },
];

export const useViewIntro = (): ViewResponse => {
  const [shouldSkip, refetch] = useReload();

  const [view, { error: viewError, state: viewState }] = usePrismicDocumentsByTag<ViewDocument>(
    'IntroView',
    {
      skip: shouldSkip,
    },
  );

  const firstView = view?.results[0];

  // Filter out non-IntroViewSkills slices and then flatten the array of what's left.
  const items = chain(firstView?.data.slices ?? [])
    .filter(slice => {
      return slice.slice_type === 'intro_view_skills';
    })
    .map(slice => slice as IntroViewSkillsSlice)
    .flatMap(slice => {
      return slice.items;
    })
    .map(({ module, section, skill }) => {
      const isValidItem = 'id' in skill && 'id' in section && 'id' in module;
      if (!isValidItem) {
        return undefined;
      }

      return {
        skillId: skill.id,
        sectionId: section.id,
        moduleId: module.id,
      };
    })
    .compact()
    .value();

  const skillIds = items.map(({ skillId }) => skillId);

  const [skills, { error: skillError, state: skillState }] =
    useAllPrismicDocumentsByIDs<SkillDocument>(skillIds, { skip: shouldSkip || isEmpty(skillIds) });

  const emptySkillsById: Record<string, SkillDocument> = {};
  const skillsById =
    skills?.reduce((acc, skill) => {
      acc[skill.id] = skill;
      return acc;
    }, emptySkillsById) ?? emptySkillsById;

  return [
    { view: firstView, items, skillsById },
    {
      idle: viewState === 'idle' && skillState === 'idle',
      loading: viewState === 'loading' || skillState === 'loading',
      loaded: viewState === 'loaded' && skillState === 'loaded',
      error: viewError ?? skillError,
      refetch,
    },
  ];
};
