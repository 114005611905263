import { pick } from 'lodash';
import { appendSearchToRoute, getRoute, useCurrentRouteSearch } from '../../../routes';
import { getRedirectUrl } from '../../../utils/authenticationStore';
import { useSmartNavigate } from '../../../utils/useSmartNavigate';

// For now onDemand is the only usage of this, but this could change later.
type LoginRedirectTo = 'onDemand';

type LoginRedirect = (redirectTo?: LoginRedirectTo) => Promise<void>;

// A list of search params that should be preserved (passed through) during the redirect.
// These are preserved for marketing purposes.
const searchPassthrough = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

export const useLoginRedirect = (): LoginRedirect => {
  const navigate = useSmartNavigate();
  const searchParams = useCurrentRouteSearch();

  /**
   * Redirects the user after login.
   *
   * @param redirectTo - Allows you to override the normal redirect url fetching to force the user
   * to a specific location. This is superseded by any external redirect.
   */
  return async (redirectTo?: LoginRedirectTo): Promise<void> => {
    const { external_redirect: externalRedirect } = searchParams;
    const storedRedirectUrl = await getRedirectUrl();

    let redirectUrl = getRoute('home', {});
    if (externalRedirect !== undefined && externalRedirect.trim() !== '') {
      redirectUrl = externalRedirect;
    } else if (redirectTo === 'onDemand') {
      redirectUrl = getRoute('onDemandConfirmInfo', {});
    } else if (storedRedirectUrl.trim() !== '') {
      redirectUrl = storedRedirectUrl;
    }

    // Gets a list of search parameters that should be preserved during the redirect.
    const toSearch = pick(searchParams, searchPassthrough);

    navigate(appendSearchToRoute(redirectUrl, toSearch), { replace: true });
  };
};
