import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import React from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Text } from '../core';
import { Checkbox, CheckboxProps } from '../core/Input';
import { FormErrorInput } from './FormErrorInput';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum CheckboxErrorTypes {
  Validate = 'validate', // from isRequired or rules.validate
}

type FormCheckboxPros<TFieldValues extends FieldValues> = Omit<CheckboxProps, 'value'> &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> & {
    accessibilityLabel: string;
    label?: string | JSX.Element;
    error?: FieldErrors<TFieldValues>[string];
    isRequired?: boolean;
  };

export function FormCheckbox<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  isRequired = false,
  label = '',
  rules = {},
  ...checkboxProps
}: FormCheckboxPros<TFieldValues>): JSX.Element {
  const requiredRule = (value: boolean): boolean | string => {
    if (!isRequired) {
      return true;
    }
    return Boolean(value) || 'This field is required';
  };

  const finalRules = {
    ...rules,
    validate: defaultTo(rules.validate, requiredRule),
  };

  return (
    <FormControl isInvalid={Boolean(error?.type)} marginY={1} onLayout={onLayout}>
      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            {...checkboxProps}
            isInvalid={checkboxProps.isInvalid ?? Boolean(error)}
            onChange={onChange}
            value={value}
          >
            {Boolean(label) && <Text.caption bold>{label}</Text.caption>}
          </Checkbox>
        )}
      />

      {error && <FormErrorInput>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
