import { StyledProps } from 'native-base';
import { PlatformProps } from 'native-base/src/components/types/PlatformProps';

export type FullStyledProps = StyledProps &
  PlatformProps<StyledProps> & {
    // Gap now works in react-native but native base doesn't know that yet.
    gap?: number;
  };

export const getStylesheet = <T extends string>(
  styleData: Readonly<Record<T, FullStyledProps>>,
): { [item in T]: FullStyledProps } => {
  return styleData;
};
