import { PrismicRichText } from '@prismicio/react';
import { defaultsDeep } from 'lodash';
import { ComponentProps, ReactNode } from 'react';
import { Heading, Text, TextList } from '../core';

import type { RTLabelNode } from '@prismicio/types';
import type { StyledProps } from 'native-base';

/**
 * Text - Put on every element with actual text inside it.
 * Heading - Put on every heading element.
 */
type StylesOverride = {
  text?: StyledProps;
  heading?: StyledProps;
  heading1?: StyledProps;
  heading2?: StyledProps;
  heading3?: StyledProps;
  heading4?: StyledProps;
  heading5?: StyledProps;
  heading6?: StyledProps;
  list?: StyledProps;
  listItem?: StyledProps;
  paragraph?: StyledProps;
};

type RichTextProps = ComponentProps<typeof PrismicRichText> & {
  styles?: StylesOverride;
};

export const RichText = (props: RichTextProps): JSX.Element => {
  const overrideStyles = defaultsDeep(props.styles, {
    heading: {
      marginBottom: 4,
    },
    paragraph: {
      marginBottom: 4,
    },
    list: {
      marginBottom: 4,
      space: 3,
    },
  });

  return <PrismicRichText components={richTextComponentsMap(overrideStyles)} {...props} />;
};

type SubProps = { children: ReactNode; key: string };
type LabelProps = SubProps & { node: RTLabelNode };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const richTextComponentsMap = (styles: StylesOverride) => ({
  heading1: ({ children, key }: SubProps) => (
    <Heading.h1 {...styles.text} {...styles.heading} {...styles.heading1} key={key}>
      {children}
    </Heading.h1>
  ),
  heading2: ({ children, key }: SubProps) => (
    <Heading.h2 {...styles.text} {...styles.heading} {...styles.heading2} key={key}>
      {children}
    </Heading.h2>
  ),
  heading3: ({ children, key }: SubProps) => (
    <Heading.h3 {...styles.text} {...styles.heading} {...styles.heading3} key={key}>
      {children}
    </Heading.h3>
  ),
  heading4: ({ children, key }: SubProps) => (
    <Heading.h4 {...styles.text} {...styles.heading} {...styles.heading4} key={key}>
      {children}
    </Heading.h4>
  ),
  heading5: ({ children, key }: SubProps) => (
    <Heading.h5 {...styles.text} {...styles.heading} {...styles.heading5} key={key}>
      {children}
    </Heading.h5>
  ),
  heading6: ({ children, key }: SubProps) => (
    <Heading.h6 {...styles.text} {...styles.heading} {...styles.heading6} key={key}>
      {children}
    </Heading.h6>
  ),
  paragraph: ({ children, key }: SubProps) => (
    <Text.para {...styles.text} {...styles.paragraph} key={key}>
      {children}
    </Text.para>
  ),

  list: ({ children, key }: SubProps) => (
    <TextList.List {...styles.list} key={key}>
      {children}
    </TextList.List>
  ),
  oList: ({ children, key }: SubProps) => (
    <TextList.List {...styles.list} key={key}>
      {children}
    </TextList.List>
  ),
  listItem: ({ children, key }: SubProps) => (
    <TextList.Item {...styles.listItem} key={key}>
      {children}
    </TextList.Item>
  ),
  oListItem: ({ children, key }: SubProps) => (
    <TextList.Item {...styles.listItem} key={key}>
      {children}
    </TextList.Item>
  ),

  strong: ({ children, key }: SubProps) => (
    <Text.Text {...styles.text} key={key} bold>
      {children}
    </Text.Text>
  ),
  // eslint-disable-next-line id-length
  em: ({ children, key }: SubProps) => (
    <Text.Text {...styles.text} key={key} fontStyle="italic">
      {children}
    </Text.Text>
  ),
  label: ({ children, key, node }: LabelProps) => {
    if (node.data.label === 'underline') {
      return (
        <Text.Text {...styles.text} key={key} textDecorationLine="underline">
          {children}
        </Text.Text>
      );
    }
    return (
      <Text.Text {...styles.text} key={key}>
        {children}
      </Text.Text>
    );
  },
});
