import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import React from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Select } from '../core';
import { SelectProps } from '../core/Select';
import { FormErrorInput } from './FormErrorInput';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum SelectErrorTypes {
  Validate = 'required', // from isRequired or rules.required
}

type FormSelectProps<TFieldValues extends FieldValues> = SelectProps<string> &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  FormLabelProps & {
    error?: FieldErrors<TFieldValues>[string];
    isRequired?: boolean;
  };

export function FormSelect<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  options,
  isRequired = false,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...selectProps
}: FormSelectProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(isRequired),
      message: 'This field is required',
    }),
  };

  return (
    <FormControl isInvalid={Boolean(error?.type)} isReadOnly onLayout={onLayout}>
      <FormLabel label={label} necessityIndicator={necessityIndicator} isRequired={isRequired} />

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            {...selectProps}
            options={options}
            selectedValue={value}
            onValueChange={onChange}
            ref={ref}
          />
        )}
      />

      {error && <FormErrorInput>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
