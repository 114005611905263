import { castArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SimpleModal } from '../../../components/core';
import { Slides } from '../../../components/slides/Slides';
import { AssessmentForm } from '../../../content/assessment/components/AssessmentForm';
import { AssessmentContinueArgs } from '../../../content/assessment/components/AssessmentSlideTypes';
import { getQuestions } from '../../../content/assessment/utils/assessmentUtils';
import { Questionnaire } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { useFindCareContext } from '../context/findCareContext';
import { useFindCareSlideContext } from '../context/findCareSlideContext';
import { FindCareCrisisSupportPage } from '../FindCareCrisisSupportPage';
import { useFindCareRedirect } from '../hooks/useFindCareRedirect';

type FindCareAssessmentSlidesProps = {
  assessmentType: string;
  questionnaire: Questionnaire;
  onDemandEnabled: boolean;
};

export const FindCareAssessmentSlides = ({
  assessmentType,
  questionnaire,
  onDemandEnabled,
}: FindCareAssessmentSlidesProps): JSX.Element => {
  const navigate = useNavigate();
  const { setAnswer } = useFindCareContext();
  const findCareSlideContext = useFindCareSlideContext();

  const [showOnDemand, setShowOnDemand] = useState(false);

  const { questions, slides } = useMemo(() => {
    const theQuestions = getQuestions(questionnaire);

    return { questions: theQuestions, slides: theQuestions.map(() => AssessmentForm) };
  }, [questionnaire]);

  const { nextSlide, registerNavigation, registerSlides, slideNumber, triggerNextSlide } =
    findCareSlideContext;

  useFindCareRedirect();

  useEffect(() => {
    if (slides === undefined) {
      return;
    }

    registerNavigation((newSlideNumber: string): void => {
      navigate(getRoute('findCareAssessments', { assessmentType, slide: newSlideNumber }));
    });

    registerSlides(slides);
  }, [assessmentType]);

  const onContinue = (): void => {
    if (nextSlide) {
      triggerNextSlide();
      return;
    }

    if (assessmentType === 'mental-health-history') {
      navigate(getRoute('findCarePreQuantitative', {}));
    }
    if (assessmentType === 'phq') {
      navigate(getRoute('findCareAssessments', { assessmentType: 'gad', slide: '1' }));
    }
    if (assessmentType === 'gad') {
      // this is the last slide in the last assessment, navigate to submission page.
      navigate(getRoute('findCareReviewAnswers', {}));
    }
  };

  const onSubmitAnswer = ({ question, answer }: AssessmentContinueArgs): void => {
    setAnswer(question.assessmentKey, question.key, answer);

    const answerKey = answer.answerKeys;

    if (shouldShowOnDemand(onDemandEnabled, answerKey)) {
      setShowOnDemand(true);
      return;
    }

    onContinue();
  };

  const onModalClose = (): void => {
    setShowOnDemand(false);
    onContinue();
  };

  return (
    <>
      {showOnDemand && (
        <SimpleModal onClose={onModalClose} marginX={4}>
          <FindCareCrisisSupportPage
            setShowCrisisSupport={setShowOnDemand}
            slideNumber={slideNumber}
          />
        </SimpleModal>
      )}

      <Slides context={findCareSlideContext} questions={questions} onContinue={onSubmitAnswer} />
    </>
  );
};

const shouldShowOnDemand = (onDemandEnabled: boolean, answerKeys?: string[] | null): boolean => {
  if (!onDemandEnabled || answerKeys == null) {
    return false;
  }

  const YES_WITHIN_PAST_MONTH = 'yes-within-past-month';
  const YES_7_PLUS_DAYS = 'yes-7-plus-days';
  const valueToCheck = [YES_WITHIN_PAST_MONTH, YES_7_PLUS_DAYS];

  return castArray(answerKeys).some(answerKey => valueToCheck.includes(answerKey));
};
