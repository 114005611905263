import { ITextProps, StyledProps, Text as NativeText } from 'native-base';
import React, { ComponentProps, FC } from 'react';

import { NavigateOptions, To, useSmartNavigate } from '../../utils/useSmartNavigate';

type LinkProps = ComponentProps<typeof NativeText> & {
  bold?: boolean;
  to: To;
  toOptions?: NavigateOptions;
};

export function Link({ to, toOptions, ...props }: LinkProps): JSX.Element {
  const smartNavigate = useSmartNavigate();

  // This can be overridden by providing an onPress prop.
  const onPress = (): void => {
    smartNavigate(to, toOptions);
  };

  return <NativeText onPress={onPress} {...props} />;
}

const linkFactory =
  (textStyle: Partial<ITextProps>): FC<LinkProps> =>
  ({ children, bold, ...props }) => {
    return (
      <Link {...textStyle} bold={bold} {...props}>
        {children}
      </Link>
    );
  };

const generalLinkStyles: StyledProps = {
  color: 'primary.600',
};

// LINKS
export const para = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraph',
});

export const paraSmall = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraphSmall',
});

export const paraLarge = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraphLarge',
});

export const caption = linkFactory({
  ...generalLinkStyles,
  variant: 'caption',
});
