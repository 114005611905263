import { FindCareUserQuery } from '../../../graphQL';

export const checkRequiredFields = (hubUser: FindCareUserQuery['currentHubUser']): boolean => {
  const { primaryAddress } = hubUser;
  const toCheck = [
    hubUser.firstName,
    hubUser.lastName,
    hubUser.phone,
    hubUser.birthDate,
    primaryAddress?.line1,
    primaryAddress?.city,
    primaryAddress?.state,
    primaryAddress?.zip,
  ];

  return toCheck.every(Boolean);
};
