import { castArray } from 'lodash';
import { JSX, ReactElement } from 'react';
import { getStylesheet } from '../../../styles';
import { Divider, Heading, Layout, Text } from '../index';
import { Task, TaskProps } from './Task';

type TaskListProps = {
  children: Array<ReactElement<TaskProps, typeof Task>> | ReactElement<TaskProps, typeof Task>;
  headerText?: string;
  description?: string;
};

export const TaskList = ({
  children,
  headerText,
  description,
}: TaskListProps): JSX.Element | null => {
  const filteredChildren = castArray(children).filter(
    ({ type, props }) => type.name === 'Task' && !props.completed,
  );

  if (filteredChildren.length < 1) {
    return null;
  }

  return (
    <Layout.VStack
      {...styles.taskList}
      divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
    >
      {headerText !== undefined && (
        <Layout.VStack {...styles.headerSection} space={1}>
          <Heading.h4>{headerText}</Heading.h4>

          {description !== undefined && <Text.para>{description}</Text.para>}
        </Layout.VStack>
      )}

      <Layout.VStack divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}>
        {filteredChildren}
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  taskList: {
    borderRadius: 4,
    overflow: 'hidden',
  },

  headerSection: {
    backgroundColor: 'white',
    padding: 4,
  },
});
