import { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { usePasswordRules } from '../hooks/usePasswordRules';

type SignupEmailFormProps = {
  token: string;
  tokenId: string;
  onSuccess: () => void;
};

export const NewPasswordForm = ({
  onSuccess,
  token,
  tokenId,
}: SignupEmailFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const loading = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setNewPassword = (props: { password: string; token: string; tokenId: string }): void => {
    onSuccess();
  };
  // const [setNewPassword, { loading }] = useHubNewPasswordMutation({
  //   onCompleted: () => {
  //     onSuccess();
  //   },
  //   onError: authError => {
  //     formContext.setError('submit', authError);
  //   },
  // });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ password }): Promise<void> => {
      await setNewPassword({ password, token, tokenId });
    })();
  };

  const passwordValue = watch('password');

  const { passwordRules, confirmPasswordRules } = usePasswordRules(passwordValue);

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInput
            label="New password"
            name="password"
            placeholder="Password"
            type="password"
            rules={passwordRules}
            autoFocus
            isRequired
            control={control}
            error={errors.password}
            onSubmitEditing={submitForm}
          />

          <FormInput
            label="Confirm new password"
            name="passwordConfirm"
            placeholder="Confirm Password"
            type="password"
            rules={confirmPasswordRules}
            isRequired
            control={control}
            error={errors.passwordConfirm}
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-new-password-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
