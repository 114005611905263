import React from 'react';
import { Text, Layout, Heading, Button, StatusFeedback } from '../../components/core';
import { IconAlertCircle } from '../../components/icons/IconAlertCircle';
import { IconAlertTriangle } from '../../components/icons/IconAlertTriangle';
import { IconExternalLink } from '../../components/icons/IconExternalLink';
import { PageContent } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';

const TRIAGE_QUESTIONNAIRE_SIZE = 8;

type FindCareCrisisSupportPageProps = {
  setShowCrisisSupport: (showOnDemand: boolean) => void;
  slideNumber: number;
};

export const FindCareCrisisSupportPage = ({
  setShowCrisisSupport,
  slideNumber,
}: FindCareCrisisSupportPageProps): JSX.Element => {
  const navigate = useNavigate();

  const onNavigate = (): void => {
    if (slideNumber >= TRIAGE_QUESTIONNAIRE_SIZE) {
      navigate(getRoute('findCarePreQuantitative', {}));
    } else {
      navigate(
        getRoute('findCareAssessments', {
          assessmentType: 'mental-health-history',
          slide: String(slideNumber + 1),
        }),
      );
    }

    setShowCrisisSupport(false);
  };

  return (
    <PageContent>
      <Layout.VStack space={4}>
        <IconAlertTriangle size={6} />
        <Heading.h5>BEFORE CONTINUING...</Heading.h5>
        <Heading.h2>Are you in need of immediate crisis support?</Heading.h2>
        <Text.para>
          For your safety, we want to pause this assessment to offer you in-the-moment support. If
          you don't feel this applies to you or your situation, please continue the assessment.
        </Text.para>

        <StatusFeedback.warning
          Icon={IconAlertCircle}
          testID="find-care-crisis-support-assessment-warning"
          vertical
        >
          <Text.para bold>
            Note: Your assessment progress will not be saved if you leave to access on-demand crisis
            resources.
          </Text.para>
        </StatusFeedback.warning>

        <Button.dangerLarge
          testID="button-visit-on-demand-resources"
          onPress={() => navigate(getRoute('onDemandHome', {}))}
          rightIcon={<IconExternalLink />}
        >
          Get support now
        </Button.dangerLarge>
        <Button.secondaryLarge testID="button-continue-assessment" onPress={onNavigate}>
          Continue assessment
        </Button.secondaryLarge>
      </Layout.VStack>
    </PageContent>
  );
};
