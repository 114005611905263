import { Button, Heading, Layout, Link, SimpleModal, Text } from '../../components/core';
import { getRoute } from '../../routes';

type IneligibleEmailModalProps = {
  onClose: () => void;
};

export const OldIneligibleEmailModal = ({
  onClose,
}: IneligibleEmailModalProps): JSX.Element | null => {
  return (
    <SimpleModal onClose={onClose} padding={6} paddingTop={12}>
      <Layout.VStack space={12}>
        <Layout.VStack space={2}>
          <Heading.h4>Ineligible email address</Heading.h4>
          <Text.para>
            Mantra Care Hub is currently only available to students of select schools using a
            school-issued email address.
          </Text.para>
          <Text.para bold>
            If you are an existing Mantra Health patient,{' '}
            <Link.para to={getRoute('patientPortal', {})}>
              click here to log into the Patient Portal.
            </Link.para>
          </Text.para>
        </Layout.VStack>

        <Layout.HStack space={3}>
          <Button.secondaryMedium testID="button-sso-ineligible-close" onPress={onClose}>
            Close
          </Button.secondaryMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};
