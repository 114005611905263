import { differenceInYears, parse } from 'date-fns';
import { trim } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Masks } from 'react-native-mask-input';
import { Button, Layout, Link } from '../../../components/core';
import {
  FormCheckbox,
  FormErrorSubmit,
  FormInput,
  FormInputMasked,
} from '../../../components/form';
import {
  LINK_TO_HEALTH_TERMS,
  LINK_TO_TELEHEALTH_CONSENT,
  LINK_TO_PRIVACY_POLICY,
} from '../../../constants/links';
import { getStylesheet } from '../../../styles';
import { DATE_REGEX, convertDateForDisplay } from '../../../utils/date';
import { useOnboardUser } from '../hooks/useOnboardUser';

type OnboardingFormProps = {
  defaultUserName: string;
  currentBirthDate?: string;
  onSaved: () => void;
  onUnderage: () => void;
};

export const OnboardingForm = ({
  defaultUserName,
  onUnderage,
  onSaved,
  currentBirthDate,
}: OnboardingFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      preferredName: defaultUserName,
      birthDate: convertDateForDisplay(currentBirthDate) ?? '',
      terms: false,
      submit: undefined,
    },
  });

  const hasPrefilledBirthDate = Boolean(currentBirthDate);

  const [onboardUser, { loading }] = useOnboardUser({
    onCompleted: (): void => {
      onSaved();
    },
    onError: saveNameError => {
      formContext.setError('submit', saveNameError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ preferredName, birthDate }): Promise<void> => {
      if (!hasPrefilledBirthDate && isUnderage(birthDate)) {
        onUnderage();
        return;
      }

      await onboardUser(trim(preferredName), birthDate);
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6} width={'100%'}>
        <FormInput
          label="What should we call you? (optional)"
          name="preferredName"
          placeholder="Preferred name"
          autoCapitalize="none"
          control={control}
          error={errors.preferredName}
          maxLength={100}
        />

        <FormInputMasked
          label="Date of Birth"
          mask={Masks.DATE_MMDDYYYY}
          name="birthDate"
          placeholder="MM/DD/YYYY"
          autoCapitalize="none"
          control={control}
          error={errors.birthDate}
          isDisabled={hasPrefilledBirthDate}
          isRequired
          necessityIndicator
          rules={{
            pattern: {
              value: DATE_REGEX,
              message: 'You must enter a valid date MM/DD/YYYY.',
            },
          }}
        />

        <Layout.View {...styles.checkBox}>
          <FormCheckbox
            accessibilityLabel="Agree to terms"
            name="terms"
            label={
              <>
                I agree to{' '}
                <Link.caption to={LINK_TO_HEALTH_TERMS}>
                  Mantra Health Terms &amp; Conditions
                </Link.caption>
                , <Link.caption to={LINK_TO_TELEHEALTH_CONSENT}>Telehealth Consent</Link.caption>,{' '}
                <Link.caption to={LINK_TO_PRIVACY_POLICY}>Privacy Policy</Link.caption>, and
                receiving periodic emails from Mantra Health (you may opt out at any time).
              </>
            }
            control={control}
            error={errors.terms}
            isRequired
          />
        </Layout.View>

        {errors.submit && <FormErrorSubmit>{errors.submit.message}</FormErrorSubmit>}

        <Button.primaryMedium
          disabled={loading}
          testID="button-onboarding-submit"
          onPress={submitForm}
        >
          Continue
        </Button.primaryMedium>
      </Layout.VStack>
    </FormProvider>
  );
};

const styles = getStylesheet({
  checkBox: {
    width: '100%',
    maxWidth: 320,

    _web: {
      width: '100%',
    },
  },
});

const isUnderage = (dateString: string): boolean => {
  if (!dateString) {
    return false;
  }

  const today = new Date();
  const birthDate = parse(dateString, 'MM/dd/yyyy', new Date());
  const ageInYears = differenceInYears(today, birthDate);

  return ageInYears < 18;
};
