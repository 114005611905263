import { defaultTo } from 'lodash';
import React, { useState } from 'react';
import { Platform } from 'react-native';
import { Button, Heading, Layout, Skeleton } from '../../../components/core';
import { Vimeo } from '../../../components/vimeo/Vimeo';
import { getStylesheet } from '../../../styles';
import { SkillBox } from '../../skill/SkillBox';
import { useViewIntro } from '../hooks/useViewIntro';

export const IntroView = (): JSX.Element => {
  const [showSkills, setShowSkills] = useState(false);
  const [{ view, items, skillsById }, { loaded, error }] = useViewIntro();

  const isReady = Boolean(items && view && loaded && !error);

  const title = defaultTo(view?.data.title, '');

  return (
    <Layout.View testID="intro-view">
      <Layout.VStack space={4}>
        {!showSkills && (
          <>
            <Heading.h3 center>How to live your best life</Heading.h3>

            <Vimeo
              videoId="780492233/bb11852abb"
              height={Platform.OS !== 'web' ? 200 : undefined}
            />

            <Button.primaryLarge
              alignSelf="center"
              testID="button-self-care-intro-try-it-out"
              onPress={() => setShowSkills(true)}
            >
              Try it Out
            </Button.primaryLarge>
          </>
        )}

        {showSkills && !isReady && (
          <>
            <Layout.View>
              <Layout.VStack space={4}>
                {!loaded ? (
                  <Skeleton backgroundColor="white" height={148} />
                ) : (
                  <Heading.h4>Could not load</Heading.h4>
                )}
              </Layout.VStack>
            </Layout.View>
          </>
        )}

        {showSkills && isReady && (
          <>
            {title !== '' && <Heading.h4 center>{title}</Heading.h4>}

            <Layout.Flex {...styles.skillsList} testID="intro-view">
              {items?.map(({ moduleId, skillId }) => {
                const fullSkill = skillsById[skillId];
                if (!fullSkill) {
                  return null;
                }

                return (
                  <SkillBox
                    hideTitle
                    navToIntroSkill
                    minWidth="45%"
                    flexBasis="45%"
                    key={skillId}
                    moduleId={moduleId}
                    skill={fullSkill}
                  />
                );
              })}
            </Layout.Flex>
          </>
        )}
      </Layout.VStack>
    </Layout.View>
  );
};

const styles = getStylesheet({
  skillsList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    justifyContent: 'space-between',
    width: '100%',
  },
});
