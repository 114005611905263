import React from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageHeader, PageLoading } from '../../components/page';
import { useCareRecommendation } from './hooks/useCareRecommendation';

export const RecommendationPage = (): JSX.Element => {
  const [
    { topStatusFeedbacks, careRecommendationCTAs, bottomStatusFeedbacks, selfServiceCTAs },
    { loading, error },
  ] = useCareRecommendation();

  if (loading) {
    return <PageLoading pageName="recommendation" />;
  }
  if (error) {
    return <PageError />;
  }

  return (
    <PageContent testID="recommendation-page">
      <Layout.VStack space={'2xl'}>
        <PageHeader
          title="Your personalized care recommendation"
          undertext="Based on your answers, we have tailored care recommendations that best support your mental health care journey."
          testID="recommendation-header"
        />
        {topStatusFeedbacks.map(statusFeedback => statusFeedback)}
        {careRecommendationCTAs.map(careRecommendationCTA => careRecommendationCTA)}
        {bottomStatusFeedbacks.map(statusFeedback => statusFeedback)}
        {selfServiceCTAs.length > 0 && (
          <>
            <Layout.VStack space={2}>
              <Heading.h3>Additional self-serve tools</Heading.h3>
              <Text.paraSmall>
                Access the following tools at any time from within the Mantra Care Hub.
              </Text.paraSmall>
            </Layout.VStack>
            <Layout.VStack space={7}>
              {selfServiceCTAs.map(selfServiceCTA => selfServiceCTA)}
            </Layout.VStack>
          </>
        )}
      </Layout.VStack>
      <PageFooter />
    </PageContent>
  );
};
