import { ApolloError } from '@apollo/client';
import { defaultTo } from 'lodash';
import { useOrganizationLogoQuery } from '../../../../graphQL';

type OrganizationLogo = [
  string,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

export const useOrganizationLogo = (): OrganizationLogo => {
  const { data, loading, error } = useOrganizationLogoQuery();

  const url = defaultTo(data?.currentHubUser.organizationLogo?.url, '');

  return [url, { loading, error }];
};
