import { FormControl } from 'native-base';
import React from 'react';
import { Text } from '../core';

export type FormLabelProps = {
  label?: string;
  isRequired?: boolean;
  necessityIndicator?: boolean;
};

export const FormLabel = ({
  label = '',
  necessityIndicator = false,
  isRequired = false,
}: FormLabelProps): JSX.Element => {
  if (label === '') {
    return <></>;
  }

  return (
    <FormControl.Label>
      <Text.para>
        {label}{' '}
        {necessityIndicator && isRequired && (
          <Text.label alignSelf="center" color="red.500">
            *
          </Text.label>
        )}
        {necessityIndicator && !isRequired && (
          <Text.para italic alignSelf="center">
            - optional
          </Text.para>
        )}
      </Text.para>
    </FormControl.Label>
  );
};
