import { ComponentProps, ReactNode } from 'react';
import { Layout, Text } from '../core';

type LayoutViewProps = ComponentProps<typeof Layout.View>;

type FormErrorSubmitProps = {
  children: ReactNode;
} & LayoutViewProps;

export const FormErrorSubmit = ({ children, ...props }: FormErrorSubmitProps): JSX.Element => {
  return (
    <Layout.View {...props}>
      <Text.para color="error.600">{children}</Text.para>
    </Layout.View>
  );
};
