import { trim } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { useSetPreferredNameMutation } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

type PreferredNameFormProps = {
  defaultUserName: string;
  onSaved: () => void;
};

export const PreferredNameForm = ({
  defaultUserName,
  onSaved,
}: PreferredNameFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      preferredName: defaultUserName,
      submit: undefined,
    },
  });

  const [setName] = useSetPreferredNameMutation({
    onCompleted: (): void => {
      onSaved();
    },
    onError: saveNameError => {
      formContext.setError('submit', saveNameError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  // to enable the update button when preferredName is edited
  const preferredNameValue = watch('preferredName');

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async ({ preferredName }): Promise<void> => {
      await setName({
        variables: {
          preferredName: trim(preferredName),
        },
      });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <FormInput
          label="Preferred Name"
          name="preferredName"
          placeholder="What should we call you?"
          autoCapitalize="none"
          control={control}
          error={errors.preferredName}
          maxLength={100}
        />

        {errors.submit && <FormErrorSubmit>{errors.submit.message}</FormErrorSubmit>}

        <Button.primaryMedium
          {...styles.submitButton}
          isDisabled={preferredNameValue === defaultUserName}
          testID="button-edit-preferred-name-submit"
          onPress={submitForm}
        >
          Update
        </Button.primaryMedium>
      </Layout.VStack>
    </FormProvider>
  );
};

const styles = getStylesheet({
  submitButton: {
    alignSelf: 'flex-start',
    maxWidth: '90px',
    width: '100%',
  },
});
