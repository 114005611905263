import { useMemo } from 'react';
import { RecommendationOptions, useCareRecommendationGetForUserQuery } from '../../../graphQL';
import {
  AssessmentVisitCTACard,
  TherapyCTACard,
  EmotionalWellnessCoachingCTACard,
  SelfCareCTACard,
} from '../components/CareRecommendationCTACards';
import {
  AssessmentVisitFeedback,
  CoachingSelfCareFeedback,
} from '../components/CareRecommendationStatusFeedbacks';
import {
  TogetherAllCTACard,
  OdesCTACard,
  SelfCareSelfServiceCTACard,
} from '../components/SelfServiceOptions';

type CareRecommendationInputs = {
  careRecommendation: RecommendationOptions | undefined;
  onDemandEnabled: boolean;
  togetherAllEnabled: boolean;
};

type CareRecommendationOutputs = {
  topStatusFeedbacks: JSX.Element[];
  careRecommendationCTAs: JSX.Element[];
  bottomStatusFeedbacks: JSX.Element[];
  selfServiceCTAs: JSX.Element[];
};

type UseCareRecommendationReturn = [
  CareRecommendationOutputs,
  {
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

export const useCareRecommendation = (): UseCareRecommendationReturn => {
  const {
    data: careRecommendationData,
    loading: careRecommendationLoading,
    error: careRecommendationError,
  } = useCareRecommendationGetForUserQuery();

  const { careRecommendation, onDemandEnabled, togetherAllEnabled } =
    careRecommendationData?.careRecommendationGetForUser ?? {
      careRecommendation: undefined,
      onDemandEnabled: false,
      togetherAllEnabled: false,
    };

  const { topStatusFeedbacks, careRecommendationCTAs, bottomStatusFeedbacks, selfServiceCTAs } =
    useMemo(
      () => prepRecommendationContent({ careRecommendation, onDemandEnabled, togetherAllEnabled }),
      [careRecommendation],
    );

  return [
    { topStatusFeedbacks, careRecommendationCTAs, bottomStatusFeedbacks, selfServiceCTAs },
    {
      loading: careRecommendationLoading,
      loaded: !careRecommendationLoading,
      error: careRecommendationError,
    },
  ];
};

const prepRecommendationContent = ({
  careRecommendation,
  onDemandEnabled,
  togetherAllEnabled,
}: CareRecommendationInputs): CareRecommendationOutputs => {
  const topStatusFeedbacks: JSX.Element[] = [];
  const careRecommendationCTAs: JSX.Element[] = [];
  const bottomStatusFeedbacks: JSX.Element[] = [];
  const selfServiceCTAs: JSX.Element[] = [];

  switch (careRecommendation) {
    case 'assessmentVisit':
      topStatusFeedbacks.push(<AssessmentVisitFeedback key="assessment-visit-status-feedback" />);
      careRecommendationCTAs.push(<AssessmentVisitCTACard />);
      break;
    case 'therapy':
      careRecommendationCTAs.push(<TherapyCTACard />);
      break;
    case 'coaching':
      careRecommendationCTAs.push(<EmotionalWellnessCoachingCTACard />, <SelfCareCTACard />);
      bottomStatusFeedbacks.push(<CoachingSelfCareFeedback />);
      break;
    default:
  }

  if (togetherAllEnabled && careRecommendation !== RecommendationOptions.AssessmentVisit) {
    selfServiceCTAs.push(<TogetherAllCTACard key="togetherall-self-service-cta-card" />);
  }
  if (onDemandEnabled) {
    selfServiceCTAs.push(<OdesCTACard key="odes-self-service-cta-card" />);
  }
  if (careRecommendation !== RecommendationOptions.Coaching) {
    selfServiceCTAs.push(<SelfCareSelfServiceCTACard key="self-care-self-service-cta-card" />);
  }

  return { topStatusFeedbacks, careRecommendationCTAs, bottomStatusFeedbacks, selfServiceCTAs };
};
