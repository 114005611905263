import { StatusFeedback } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { IconHelpCircle } from '../../../components/icons/IconHelpCircle';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { getRoute, useExternalNavigate, useNavigate } from '../../../routes';

export const AssessmentVisitFeedback = (): JSX.Element => {
  const navigate = useNavigate();
  const [verticalFeedback] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });
  return (
    <StatusFeedback.warning
      Icon={IconAlertTriangle}
      buttonText="On-Demand Emotional Support"
      testID="assessment-visit-status-feedback"
      onPress={() => navigate(getRoute('onDemandHome', {}))}
      vertical={verticalFeedback}
    >
      Reminder: Answers to this form are not monitored in real time. If you need immediate support,
      use On-Demand crisis resources.
    </StatusFeedback.warning>
  );
};

export const CoachingSelfCareFeedback = (): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  const [verticalFeedback] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });
  return (
    <StatusFeedback.info
      Icon={IconHelpCircle}
      testID="coaching-self-care-status-feedback"
      buttonText="Contact"
      onPress={() => externalNavigate('mailto:hi@mantrahealth.com')}
      vertical={verticalFeedback}
    >
      Have questions about your recommendation or need more details about your care options? Contact
      the Care Navigation Team
    </StatusFeedback.info>
  );
};
