import { createContext, ReactNode, useContext, useMemo, useReducer, useState } from 'react';
import { AssessmentAnswer } from '../../../content/assessment/utils/assessmentUtils';
import { useFindCareUserQuery, useGetCareRecommendationStatusForUserQuery } from '../../../graphQL';
import { Setter } from '../../../types/reactTypes';
import { checkRequiredFields } from '../utils/checkRequiredFields';

type AssessmentKey = string;
type QuestionKey = string;

type Answers = Record<QuestionKey, AssessmentAnswer>;
type AnswersByAssessment = Record<AssessmentKey, Answers>;
type AnswerAction = {
  answers: Answers;
  assessmentKey?: string;
  type: 'set' | 'reset';
};

type FindCareContextType = {
  answersByAssessment: AnswersByAssessment;
  hasAllRequiredFields: boolean;
  hasStarted: boolean;
  isLoading: boolean;
  recommendationIsActive: boolean;
  resetAnswers: () => void;
  setAnswer: (assessmentKey: string, questionKey: string, answer: AssessmentAnswer) => void;
  setHasStarted: Setter<boolean>;
};

const FindCareContext = createContext<FindCareContextType>({
  answersByAssessment: {},
  hasAllRequiredFields: false,
  hasStarted: false,
  isLoading: true,
  recommendationIsActive: false,
  resetAnswers: () => undefined,
  setAnswer: () => undefined,
  setHasStarted: () => undefined,
});

export const FindCareConsumer = FindCareContext.Consumer;

export const FindCareProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [hasStarted, setHasStarted] = useState(false);
  const [hasAllRequiredFields, setHasAllRequiredFields] = useState(false);

  useFindCareUserQuery({
    onCompleted: patientData => {
      const hasRequired = checkRequiredFields(patientData.currentHubUser);
      setHasAllRequiredFields(hasRequired);
    },
  });

  const [answersByAssessment, dispatch] = useReducer(
    (state: AnswersByAssessment, action: AnswerAction) => {
      if (action.type === 'reset') {
        return {};
      }

      if (action.assessmentKey === undefined) {
        return state;
      }

      const { assessmentKey } = action;

      return {
        ...state,
        [assessmentKey]: { ...(state[assessmentKey] ?? {}), ...action.answers },
      };
    },
    {},
  );

  const { data: recData, loading: isLoading } = useGetCareRecommendationStatusForUserQuery();
  const recommendationIsActive = useMemo(
    () => recData?.getCareRecommendationStatusForUser?.activeRecommendation ?? false,
    [recData],
  );

  const setAnswer = (
    assessmentKey: string,
    questionKey: string,
    answer: AssessmentAnswer,
  ): void => {
    dispatch({
      answers: {
        [questionKey]: answer,
      },
      assessmentKey,
      type: 'set',
    });
  };

  const resetAnswers = (): void => {
    dispatch({ answers: {}, type: 'reset' });
  };

  const providerValue: FindCareContextType = {
    answersByAssessment,
    hasAllRequiredFields,
    hasStarted,
    isLoading,
    recommendationIsActive,
    resetAnswers,
    setHasStarted,
    setAnswer,
  };

  return <FindCareContext.Provider value={providerValue}>{children}</FindCareContext.Provider>;
};

export const useFindCareContext = (): FindCareContextType => {
  return useContext(FindCareContext);
};
