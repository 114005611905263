import * as WebBrowser from 'expo-web-browser';
import { Linking, Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { getMobileNavigationStrategy } from '../../../utils/authentication';

type SsoNavigation = (samlEntryPoint: string) => void;

type UseSsoNavigationProps = {
  onError: (ssoNavError: { type: string; message: string }) => void;
};

export const useSsoNavigation = ({ onError }: UseSsoNavigationProps): SsoNavigation => {
  const navigate = useNavigate();

  return Platform.select({
    // web navigates the browser to the saml entry point to continue the SSO flow
    web: (samlEntryPoint: string) => window.location.replace(samlEntryPoint),

    android: async (samlEntryPoint: string) => {
      const { browserPackages } = await WebBrowser.getCustomTabsSupportingBrowsersAsync();
      if (browserPackages.length <= 0) {
        onError({
          type: 'android-no-browser',
          message: 'Please install a browser to login.',
        });
        return;
      }

      // Android opens a web browser to handle the SSO flow, and returns here when complete.
      const route = await getMobileNavigationStrategy(samlEntryPoint, 'android');
      navigate(route);
    },

    ios: async (samlEntryPoint: string) => {
      // iOS opens a web browser to handle the SSO flow, and returns here when complete.
      const route = await getMobileNavigationStrategy(samlEntryPoint, 'ios');
      navigate(route);
    },

    default: async (samlEntryPoint: string) => Linking.openURL(samlEntryPoint),
  });
};
