import { useMarkHubUserOnboardedMutation, useUpdateHubUserMutation } from '../../../graphQL';

export enum OnboardErrorType {
  UnknownError = 'unknown-error',
}

type OnboardError = {
  type: OnboardErrorType;
  message: string;
};

type OnboardUserResponse = [
  (preferredName: string, birthDate: string) => Promise<void>,
  { loading: boolean },
];

type OnboardUserArgs = {
  onCompleted: () => void;
  onError?: (error: OnboardError) => void;
};

export function useOnboardUser({ onCompleted, onError }: OnboardUserArgs): OnboardUserResponse {
  const [markOnboarded, { loading: markLoading }] = useMarkHubUserOnboardedMutation();
  const [setUserData, { loading: saveDataLoading }] = useUpdateHubUserMutation();

  const onboardUser = async (preferredName: string, birthDate: string): Promise<void> => {
    try {
      await setUserData({
        variables: {
          updates: {
            preferredName,
            birthDate,
          },
        },
      });
    } catch (setNameError) {
      onError?.({
        type: OnboardErrorType.UnknownError,
        message: 'An unknown error has occurred.',
      });
      return;
    }

    try {
      await markOnboarded();
    } catch (markError) {
      onError?.({
        type: OnboardErrorType.UnknownError,
        message: 'An unknown error has occurred.',
      });
      return;
    }

    onCompleted?.();
  };

  return [onboardUser, { loading: saveDataLoading || markLoading }];
}
