import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import React from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { TextAreaProps, TextArea } from '../core/Input';
import { FormErrorInput } from './FormErrorInput';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum InputErrorTypes {
  Pattern = 'pattern', // from rules.pattern
  Required = 'required', // from isRequired or rules.required
}

type FormTextAreaPros<TFieldValues extends FieldValues> = TextAreaProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  FormLabelProps & {
    error?: FieldErrors<TFieldValues>[string];
  };

export function FormTextArea<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...textAreaProps
}: FormTextAreaPros<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(textAreaProps.isRequired),
      message: 'This field is required',
    }),
  };

  return (
    <FormControl isInvalid={Boolean(error?.type)} onLayout={onLayout}>
      <FormLabel
        label={label}
        necessityIndicator={necessityIndicator}
        isRequired={textAreaProps.isRequired}
      />

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextArea
            autoCompleteType={undefined}
            fontSize="md"
            {...textAreaProps}
            isInvalid={textAreaProps.isInvalid ?? Boolean(error)}
            onBlur={onBlur}
            onChangeText={onChange}
            placeholderTextColor="secondary.700"
            value={value}
          />
        )}
      />

      {error && <FormErrorInput>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
