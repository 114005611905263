import { castArray, compact } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormAssessmentQuestion } from '../../../content/assessment/components/FormAssessmentQuestion';
import { AssessmentSlideTypes } from './AssessmentSlideTypes';
import { InputMonitorWarning } from './InputMonitorWarning';

type InputKeyType = `${string}-manual-input`;
type FormValues = {
  [key: string]: string | string[] | undefined;
  [inputKey: InputKeyType]: string | undefined;
};

export const AssessmentForm = ({
  questions,
  onContinue,
  slideData,
}: AssessmentSlideTypes): JSX.Element => {
  const { isFinalSlide, slideIndex } = slideData;

  // Slide number is 1-indexed, but questions are 0-indexed
  const question = questions[slideIndex - 1];

  const inputName: InputKeyType = `${question?.key ?? ''}-manual-input`;
  const formManualInputDefault = useMemo(() => {
    const hasManualInput =
      question?.options?.some(({ requiresManualInput }) => Boolean(requiresManualInput)) ?? false;

    if (!hasManualInput) {
      return {};
    }

    return { [inputName]: '' };
  }, [slideIndex, questions]);

  const formContext = useForm<FormValues>({
    defaultValues: {
      [question?.key ?? '']: '',
      ...formManualInputDefault,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  if (question?.key === undefined) {
    return <></>;
  }

  const onNextPress = (): void => {
    void handleSubmit((values): void => {
      let answer;
      if (question.type === 'free-paragraph-field' || question.type === 'free-text-field') {
        answer = {
          questionKey: question.key,
          manualInput: castArray(values[question.key])[0],
        };
      } else {
        const manualInputProp =
          values[inputName] !== undefined ? { manualInput: values[inputName] } : {};

        answer = {
          questionKey: question.key,
          answerKeys: compact(castArray(values[question.key])),
          ...manualInputProp,
        };
      }

      onContinue({
        question,
        answer,
      });
    })();
  };

  return (
    <Layout.Slide>
      <FormAssessmentQuestion
        control={control}
        error={errors[question.key]}
        name={question.key}
        inputError={errors[inputName]}
        inputName={inputName}
        question={question}
      />

      <Layout.VStack space={8}>
        <Button.primaryMedium testID="assessment-form-next" onPress={onNextPress}>
          {isFinalSlide ? 'Complete' : 'Next'}
        </Button.primaryMedium>

        <InputMonitorWarning />
      </Layout.VStack>
    </Layout.Slide>
  );
};
