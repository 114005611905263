import { format, isWithinInterval, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const holidays = [
  '2023-01-01', // New Year's Day
  '2023-01-16', // Martin Luther King Jr. Day
  '2023-05-29', // Memorial Day
  '2023-06-19', // Juneteenth
  '2023-07-04', // Independence Day
  '2023-11-23', // Thanksgiving
  '2023-11-24', // Day after Thanksgiving
  '2023-12-24', // Christmas Eve
  '2023-12-25', // Christmas Day
];

export const isDuringServiceHours = (start = '12:00', end = '23:59'): boolean => {
  const currentTime = utcToZonedTime(new Date(), 'America/New_York');
  const parsedStart = parse(start, 'HH:mm', currentTime);
  const parsedEnd = parse(end, 'HH:mm', currentTime);
  return isWithinInterval(currentTime, { start: parsedStart, end: parsedEnd });
};

export const isDuringHoliday = (): boolean => {
  const nowInNewYork = utcToZonedTime(new Date(), 'America/New_York');
  const today = format(nowInNewYork, 'yyyy-MM-dd');
  return holidays.includes(today);
};
