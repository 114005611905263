import { JSX } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconAlert } from '../../components/icons/IconAlert';
import { IconBuilding } from '../../components/icons/IconBuilding';
import { IconHeartHandShake } from '../../components/icons/IconHeartHandShake';
import { IconLifeBuoy } from '../../components/icons/IconLifeBuoy';
import { PageContent, PageFooter, PageHeader } from '../../components/page';
import { JoinAppointmentWidget } from '../../content/appointment/components/JoinAppointmentWidget';
import { ANY, Portals } from '../../content/portal/portals';
import { MobilePromotionBanner } from '../../content/promotion/components/MobilePromotionBanner';
import { View } from '../../content/view/components/View';
import { usePortalContext } from '../../contexts/portalContext';
import {
  useGetCareRecommendationStatusForUserQuery,
  useHomePageUserQuery,
  useOrganizationHasCoachingQuery,
  useOrganizationOnDemandQuery,
} from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { CoachingDashboardCard } from './components/CoachingDashboardCard';
import { FindCareDashboardCard } from './components/FindCareDashboardCard';
import { ImageSelfCareHand } from './components/ImageSelfCareHand';
import { QuickActionCard } from './components/QuickActionCard';
import { TogetherAll } from './components/TogetherAll';

export const HomePage = (): JSX.Element => {
  const navigate = useNavigate();

  const { data: userData } = useHomePageUserQuery();
  const { data: orgHasCoachingData } = useOrganizationHasCoachingQuery();
  const { data: orgHasOnDemandData } = useOrganizationOnDemandQuery();
  const { data: recommendationStatusForUser } = useGetCareRecommendationStatusForUserQuery();

  const { onlyIfPortalIsActive } = usePortalContext();
  const recommendationStatus = recommendationStatusForUser?.getCareRecommendationStatusForUser;

  const onSelfCareClick = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  const userName = userData?.currentHubUser.preferredName ?? '';
  const orgName = userData?.currentHubUser.organization?.name ?? '';
  const showCampusResources =
    userData?.currentHubUser.organizationCanAccessCampusResources ?? false;
  const showCoaching = orgHasCoachingData?.currentHubUser.organizationHasCoaching ?? false;
  const showFindCare = onlyIfPortalIsActive(Portals.Loca, true, false);
  const showTogetherAll = userData?.currentHubUser.organizationCanAccessTogetherAll ?? false;
  const showOnDemand = orgHasOnDemandData?.organizationOnDemand?.onDemandEnabled ?? false;
  const showTherapy = onlyIfPortalIsActive(
    ANY(Portals.Therapy, Portals.NonLocaTherapyGateway, Portals.Psychiatry),
    true,
    false,
  );

  const therapyCardRoute = onlyIfPortalIsActive(
    Portals.NonLocaTherapyGateway,
    'therapy',
    'patientPortal',
  );

  const userGreeting = userName !== '' ? `, ${userName}` : '';

  return (
    <PageContent testID="home-page">
      <MobilePromotionBanner />

      <PageHeader title={`Welcome${userGreeting}`} />

      <Layout.Box {...styles.topWrapper}>
        <JoinAppointmentWidget />
        {showFindCare && <FindCareDashboardCard recommendationStatus={recommendationStatus} />}

        {showCoaching && <CoachingDashboardCard />}
        <Layout.HStack style={{ width: '100%' }} space={2}>
          {showTherapy && (
            <QuickActionCard
              icon={<IconHeartHandShake size={8} color="primary.600" accessibilityHidden />}
              title="Mantra Therapy"
              caption="Access your clinical care"
              linkTo={getRoute(therapyCardRoute, {})}
            />
          )}
          {showOnDemand && (
            <QuickActionCard
              icon={<IconLifeBuoy size={8} color="primary.600" accessibilityHidden />}
              title="On-Demand Emotional Support"
              caption="Get support 24/7"
              linkTo={getRoute('onDemandHome', {})}
            />
          )}
        </Layout.HStack>

        <Layout.Flex {...styles.quickActions}>
          {showCampusResources && (
            <QuickActionCard
              icon={<IconBuilding size={8} color="primary.600" accessibilityHidden />}
              title="Campus Resources"
              caption="Find support on campus"
              linkTo={getRoute('campusResources', {})}
            />
          )}

          <QuickActionCard
            icon={<IconAlert size={8} color="red.600" accessibilityHidden />}
            title="Crisis Resources"
            caption="Emergency Resources"
            linkTo={getRoute('crisisResources', {})}
          />
        </Layout.Flex>

        <Layout.Shift {...styles.selfCareBox} alignNonMobile="center">
          <ImageSelfCareHand size="160px" />

          <Layout.VStack {...styles.selfCareTopStack} space={8}>
            <Layout.VStack space={3}>
              <Heading.h4 color="lightText">Self Care</Heading.h4>

              <Heading.h3 color="lightText">
                Get the tools to deal with whatever comes your way.
              </Heading.h3>

              <Text.para color="lightText">
                Quick, practical, evidenced-based skills to help you feel better and handle any
                situation.
              </Text.para>
            </Layout.VStack>

            <Button.primaryMedium
              {...styles.browseButton}
              testID="button-home-go-to-self-care"
              onPress={onSelfCareClick}
            >
              Browse Skills
            </Button.primaryMedium>
          </Layout.VStack>
        </Layout.Shift>

        {showTogetherAll && <TogetherAll orgName={orgName} />}

        <Layout.VStack {...styles.learn}>
          <View isCollection hideHeader />
        </Layout.VStack>
      </Layout.Box>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topWrapper: {
    gap: 4,
  },

  selfCareBox: {
    borderRadius: '8px',
    backgroundColor: 'secondary.900',
    gap: 8,
    padding: 8,
  },

  selfCareTopStack: {
    flex: 1,
  },

  browseButton: {
    maxWidth: '145px',
    _web: {
      maxWidth: '133px',
    },
  },

  quickActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    justifyContent: 'space-between',
    width: '100%',
  },

  learn: {
    justifyContent: 'center',
  },
});
