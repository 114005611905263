import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { Platform } from 'react-native';
import { useLoginHubMutation } from '../../../graphQL';
import { usePushNotifications } from '../../../plugins/pushNotifications/usePushNotifications';
import { setMobileToken } from '../../../utils/authenticationStore';

export enum LoginErrorType {
  CouldNotAuthorize = 'could-not-authorize',
  UnknownDataFetchError = 'data-fetch-error',
}

type LoginError = {
  type: LoginErrorType;
  message: string;
};

type LoginInput = {
  tokenId: string;
  oneTimeToken: string;
};

type SsoLoginResponse = [
  (input: LoginInput) => Promise<void>,
  { loading: boolean; error: ApolloError | undefined },
];

type LoginOutput = {
  userId: number;
};

type LoginHookOptions = {
  onCompleted: (output: LoginOutput) => void;
  onError?: (error: LoginError) => void;
};

export function useSsoLogin({ onCompleted, onError }: LoginHookOptions): SsoLoginResponse {
  const [loginLoading, setLoginLoading] = useState(true);

  const { registerForPushNotifications } = usePushNotifications();

  const onLoginCompleted = (info: LoginOutput): void => {
    setLoginLoading(false);
    onCompleted(info);
  };

  const onLoginError = (): void => {
    setLoginLoading(false);
    onError?.({
      type: LoginErrorType.CouldNotAuthorize,
      message: 'Could not authorize.',
    });
  };

  const [loginHubMutation, { loading, error: loginError }] = useLoginHubMutation({
    onCompleted: async data => {
      const { success, hasHubOnboarded } = data.loginHub;
      if (success?.userId === undefined) {
        onLoginError();
        return;
      }

      const mobileStrategy = async (): Promise<void> => {
        if (success.jwt == null) {
          onLoginError();
          return;
        }

        await setMobileToken(success.jwt);

        if (hasHubOnboarded === true) {
          await registerForPushNotifications();
        }

        onLoginCompleted(success);
      };

      const onCompleteStrategy = Platform.select({
        default: async () => {
          onLoginCompleted(success);
        },
        ios: async () => {
          await mobileStrategy();
        },
        android: async () => {
          await mobileStrategy();
        },
      });

      setLoginLoading(true);

      try {
        await onCompleteStrategy();
      } catch (completeError) {
        onLoginError();
      }
    },
    onError: ({ message }) => {
      setLoginLoading(false);
      onError?.({
        type: LoginErrorType.UnknownDataFetchError,
        message,
      });
    },
  });

  const login = async ({ tokenId, oneTimeToken }: LoginInput): Promise<void> => {
    if (!tokenId || !oneTimeToken) {
      return;
    }

    await loginHubMutation({
      variables: {
        tokenId,
        token: oneTimeToken,
      },
    });
  };

  return [login, { loading: loading || loginLoading, error: loginError }];
}
