import { trimStart } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { NavigateOptions } from 'react-router-dom';
import { usePortalContext } from '../../contexts/portalContext';
import { getRoute, useNavigate } from '../../routes';
import { AllowedPortal } from './portals';

type PortalToOptions = Pick<NavigateOptions, 'state'>;

export const useNavigateOnPortal = (): ((to: string, toOptions?: PortalToOptions) => void) => {
  const navigate = useNavigate();

  return (to: string, toOptions?: PortalToOptions) => {
    navigate(
      getRoute('forwardOnPortal', {
        '*': trimStart(to, '/'),
      }),
      toOptions,
    );
  };
};

export const usePortalNavigate = (): ((
  portal: AllowedPortal,
  allowedTo: string,
  fallbackTo: string,
) => void) => {
  const navigate = useNavigate();
  const { onlyIfPortalIsActive } = usePortalContext();

  return (portal: AllowedPortal, allowedTo: string, fallbackTo: string) => {
    const route = onlyIfPortalIsActive(portal, allowedTo, fallbackTo);

    navigate(route);
  };
};

export const usePortalSwitchNavigate = (): ((
  portalAndTo: Array<[AllowedPortal, string]>,
  fallbackTo: string,
) => void) => {
  const navigate = useNavigate();
  const { portalSwitch } = usePortalContext();

  return (portalAndTo: Array<[AllowedPortal, string]>, fallbackTo: string) => {
    const route = portalSwitch(portalAndTo, fallbackTo);

    navigate(route);
  };
};

type Pending = {
  portal?: AllowedPortal;
  to?: string;
  toOptions?: PortalToOptions;
};

export const usePortalNavigateWhenAvailable = (): {
  cancel: () => void;
  navigateWhenAvailable: (portal: AllowedPortal, to: string, toOptions?: PortalToOptions) => void;
  waiting: boolean;
} => {
  const [pending, setPending] = useState<Pending>({
    portal: undefined,
    to: undefined,
    toOptions: undefined,
  });

  const navigate = useNavigate();
  const { activePortals, onlyIfPortalIsActive, refetchPortals } = usePortalContext();

  const isAvailable = useMemo(() => {
    if (pending.portal === undefined) {
      return false;
    }

    return onlyIfPortalIsActive(pending.portal, true, false);
  }, [pending, activePortals]);

  useEffect(() => {
    if (pending.to === undefined || !isAvailable) {
      return;
    }

    navigate(pending.to, pending.toOptions);
  }, [pending, isAvailable]);

  const navigateWhenAvailable = (
    portal: AllowedPortal,
    to: string,
    toOptions?: PortalToOptions,
  ): void => {
    setPending({ portal, to, toOptions });

    // Force a refetch of the portals.
    refetchPortals();
  };

  const cancel = (): void => {
    setPending({
      portal: undefined,
      to: undefined,
      toOptions: undefined,
    });
  };

  return {
    cancel,
    navigateWhenAvailable,
    waiting: pending.portal !== undefined,
  };
};
