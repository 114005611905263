import { merge } from 'lodash';
import { StyledProps } from 'native-base';
import React, { ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { IconType } from '../icons';
import { IconChevronRight } from '../icons/IconChevronRight';
import { IconHelpCircle } from '../icons/IconHelpCircle';
import * as Layout from './Layout';
import { Pressable, PressableProps } from './Pressable';
import * as Text from './Text';

type VariantName = keyof typeof variantStyles;
type Variant = (typeof variantStyles)[VariantName];

const statusFeedbackFactory =
  (styles: { variant: VariantName }): typeof StatusFeedback =>
  ({ ...props }) => {
    return <StatusFeedback {...styles} {...props} />;
  };

/*
 * Variants
 */

export const info = statusFeedbackFactory({
  variant: 'info',
});

export const success = statusFeedbackFactory({
  variant: 'success',
});

export const danger = statusFeedbackFactory({
  variant: 'danger',
});

export const warning = statusFeedbackFactory({
  variant: 'warning',
});

export const simple = statusFeedbackFactory({
  variant: 'simple',
});

/*
 * StatusFeedback Component
 */

type StatusFeedbackProps = StyledProps &
  Pick<PressableProps, 'onPress' | 'testID'> & {
    children?: ReactNode;
    variant?: VariantName;
    Icon?: IconType;
    buttonText?: string;
    vertical?: boolean;
  };

export const StatusFeedback = ({
  Icon = IconHelpCircle,
  children,
  buttonText,
  onPress,
  testID,
  variant,
  vertical = false,
}: StatusFeedbackProps): JSX.Element => {
  const variantStyle: Variant = variantStyles[variant ?? 'info'];
  const finalStyles = merge({}, styles, variantStyle);

  const ButtonContent = (): JSX.Element => {
    if (!onPress) {
      return <></>;
    }
    if (vertical) {
      return (
        <Layout.Flex direction="row" alignItems="center">
          <Text.paraSmall bold>{buttonText}</Text.paraSmall>
          <IconChevronRight accessibilityHidden size={6} />
        </Layout.Flex>
      );
    }
    return <IconChevronRight {...finalStyles.icon} accessibilityHidden size={6} />;
  };

  const statusFeedbackContent = (
    <Layout.Flex
      {...styles.statusFeedbackContent}
      direction={vertical ? 'column' : 'row'}
      alignItems={vertical ? 'flex-start' : 'center'}
    >
      <Icon {...finalStyles.icon} accessibilityHidden size={6} />
      <Text.paraSmall {...styles.bodyText}>{children}</Text.paraSmall>
      <ButtonContent />
    </Layout.Flex>
  );

  if (onPress) {
    return (
      <Pressable
        {...finalStyles.statusFeedbackContainer}
        accessibilityRole="button"
        testID={testID}
        onPress={onPress}
      >
        {statusFeedbackContent}
      </Pressable>
    );
  }

  return (
    <Layout.Stack {...finalStyles.statusFeedbackContainer} testID={testID}>
      {statusFeedbackContent}
    </Layout.Stack>
  );
};

const styles = getStylesheet({
  statusFeedbackContainer: {
    borderRadius: 4,
    borderWidth: 1,
    padding: 4,
  },

  statusFeedbackContent: {
    gap: 4,
  },

  icon: {
    minWidth: '24px',
  },

  bodyText: {
    flex: 1,
  },
});

const variantStyles = {
  danger: {
    statusFeedbackContainer: {
      backgroundColor: 'danger.alpha.20:alpha.20',
      borderColor: 'danger.alpha.40:alpha.40',
    },

    icon: {
      color: 'danger.600',
    },
  },

  info: {
    statusFeedbackContainer: {
      backgroundColor: 'primary.alpha.20:alpha.20',
      borderColor: 'primary.alpha.40:alpha.40',
    },

    icon: {
      color: 'primary.600',
    },
  },

  simple: {
    statusFeedbackContainer: {
      backgroundColor: 'transparent',
      borderWidth: 0,
    },

    icon: {
      color: 'darkText',
    },
  },

  success: {
    statusFeedbackContainer: {
      backgroundColor: 'success.alpha.20:alpha.20',
      borderColor: 'success.alpha.60:alpha.60',
    },

    icon: { color: 'success.600' },
  },

  warning: {
    statusFeedbackContainer: {
      backgroundColor: 'warning.alpha.20:alpha.20',
      borderColor: 'warning.300',
    },

    icon: {
      color: 'warning.600',
    },
  },
};
