import { useState } from 'react';
import { Link, StatusFeedback, Text } from '../../../components/core';
import { ConfirmLeaveModal } from '../../../components/modals/ConfirmLeaveModal';
import { getRoute } from '../../../routes';
import { Portals } from '../../portal/portals';
import { usePortalNavigate } from '../../portal/usePortalNavigate';

export const InputMonitorWarning = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const portalNavigate = usePortalNavigate();

  const onConfirm = (): void => {
    portalNavigate(Portals.Odes, getRoute('onDemandHome', {}), getRoute('crisisResources', {}));
  };

  return (
    <>
      <StatusFeedback.warning testID="input-monitor-warning">
        <Text.paraSmall bold>Please note:</Text.paraSmall> this form is not actively monitored. If
        you’re in need of immediate assistance, please reach out to our{' '}
        <Link.paraSmall to="" onPress={() => setShowModal(true)}>
          crisis resources
        </Link.paraSmall>
        .
      </StatusFeedback.warning>

      {showModal && <ConfirmLeaveModal onClose={() => setShowModal(false)} onConfirm={onConfirm} />}
    </>
  );
};
