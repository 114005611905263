import { useToast } from 'native-base';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigateFromHubToPatientPortalLazyQuery, useTherapyContextUserQuery } from '../graphQL';
import { getRoute, useExternalNavigate, useNavigate } from '../routes';

type TherapyContextType = {
  hasLoaded: boolean;
  orgName: string;
  navigateToPatientPortal: () => Promise<void>;
  navigateToTherapy: () => Promise<void>;
  orgCanAccessTherapy: boolean;
  orgHasSelfReferrals: boolean;
};

const TherapyContext = createContext<TherapyContextType>({
  hasLoaded: false,
  orgName: '',
  navigateToPatientPortal: async () => undefined,
  navigateToTherapy: async () => undefined,
  orgCanAccessTherapy: false,
  orgHasSelfReferrals: false,
});

export const TherapyConsumer = TherapyContext.Consumer;

export const TherapyProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();
  const toast = useToast();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [orgName, setOrgName] = useState<string>('');
  const [canRedirect, setCanRedirect] = useState(false);
  const [orgCanAccessTherapy, setOrgCanAccessTherapy] = useState(false);
  const [orgHasSelfReferrals, setOrgHasSelfReferrals] = useState(false);

  const [queryForPortalLoginUrl] = useNavigateFromHubToPatientPortalLazyQuery();

  useTherapyContextUserQuery({
    onCompleted: ({ currentHubUser }) => {
      setOrgName(currentHubUser?.organization?.name ?? '');
      setCanRedirect(currentHubUser?.canRedirect ?? false);
      setOrgCanAccessTherapy(currentHubUser?.organizationCanAccessTherapy ?? false);
      setOrgHasSelfReferrals(currentHubUser?.organizationHasSelfReferrals ?? false);
      setHasLoaded(true);
    },
  });

  const navigateToTherapy = async (): Promise<void> => {
    if (canRedirect) {
      await navigateToPatientPortal();
      return;
    }

    navigate(getRoute('therapy', {}));
  };

  const navigateToPatientPortal = async (): Promise<void> => {
    if (!orgCanAccessTherapy) {
      // This org can't access therapy, so send them home.
      navigate(getRoute('home', {}));
      return;
    }

    const { data } = await queryForPortalLoginUrl();
    const loginUrl = data?.navigateFromHubToPatientPortal;

    if (loginUrl === undefined) {
      // TODO: Something else?
      toast.show({
        description: 'Could not log you into the patient portal.',
      });
      return;
    }

    externalNavigate(loginUrl);
  };

  const providerValue: TherapyContextType = {
    hasLoaded,
    orgName, // Temporary until therapy copy can come from the api.
    navigateToPatientPortal,
    navigateToTherapy,
    orgCanAccessTherapy,
    orgHasSelfReferrals,
  };

  return <TherapyContext.Provider value={providerValue}>{children}</TherapyContext.Provider>;
};

export const useTherapyContext = (): TherapyContextType => {
  return useContext(TherapyContext);
};
