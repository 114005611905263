import { SliceZone } from '@prismicio/react';
import { defaultTo } from 'lodash';
import { Heading, Layout, Skeleton, Text } from '../../../components/core';
import { IconBookOpen } from '../../../components/icons/IconBookOpen';
import { getStylesheet } from '../../../styles';
import { useViewByType } from '../hooks/useViewByType';
import { components as sliceComponents } from './slice';

type ViewProps = {
  hideHeader?: boolean;
  isCollection: boolean;
  omitModuleId?: string;
};

export const View = ({ hideHeader, isCollection, omitModuleId }: ViewProps): JSX.Element => {
  const [{ view, modulesById }, { loading, error }] = useViewByType(
    isCollection ? 'collections' : 'topics',
  );

  if (!view || loading || error) {
    return (
      <Layout.View>
        <Layout.VStack space={4}>
          {loading ? (
            <Skeleton backgroundColor="white" height={148} />
          ) : (
            <Heading.h4>Could not load</Heading.h4>
          )}
        </Layout.VStack>
      </Layout.View>
    );
  }

  const data = view.data;
  const title = defaultTo(data.title, '');
  const description = defaultTo(data.description, '');
  const shouldHideHeader = Boolean(hideHeader);

  const viewContext = {
    isCollection,
    modulesById,
    omitModuleId,
  };

  return (
    <Layout.View>
      <Layout.VStack space={isCollection ? 10 : 4}>
        {!shouldHideHeader && (
          <Layout.VStack
            {...styles.text}
            {...(isCollection ? styles.textCollection : {})}
            space={4}
          >
            {isCollection && <IconBookOpen size={6} />}
            {title && !isCollection && <Heading.h4>{title}</Heading.h4>}
            {title && isCollection && <Heading.h2 center>{title}</Heading.h2>}

            {description !== '' && <Text.para {...styles.description}>{description}</Text.para>}
          </Layout.VStack>
        )}

        <SliceZone context={viewContext} components={sliceComponents} slices={data.slices} />
      </Layout.VStack>
    </Layout.View>
  );
};

const styles = getStylesheet({
  text: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    flex: 1,
    width: '100%',
  },

  textCollection: {
    alignItems: 'center',
    maxWidth: '461px',
  },

  description: {
    textAlign: 'center',
  },
});
