import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { SignupErrorModal, SignupErrorType } from '../components/SignupErrorModal';
import { usePasswordRules } from '../hooks/usePasswordRules';

type SignupEmailFormProps = {
  onSuccess: () => void;
  token: string;
  tokenId: string;
};

export const SignupPasswordForm = ({
  onSuccess,
  token,
  tokenId,
}: SignupEmailFormProps): JSX.Element => {
  const [signupErrorType, setSignupErrorType] = useState<SignupErrorType | undefined>();
  const formContext = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const loading = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const signupUser = (props: { password: string; token: string; tokenId: string }): void => {
    onSuccess();
  };
  // const [signupUser, { loading }] = useSignupViaHubMutation({
  //   onCompleted: () => {
  //     onSuccess();
  //   },
  //   onError: authError => {
  //     // Some of the errors should show the signup error modal.
  //     // setSignupErrorType('unavailable');
  //
  //     // And other errors should show a submit error.
  //     // formContext.setError('submit', authError);
  //   },
  // });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ password }): Promise<void> => {
      await signupUser({ token, tokenId, password });
    })();
  };

  const onErrorClose = (): void => {
    setSignupErrorType(undefined);
  };

  const passwordValue = watch('password');

  const { passwordRules, confirmPasswordRules } = usePasswordRules(passwordValue);

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInput
            label="Enter your password"
            name="password"
            placeholder="Password"
            type="password"
            rules={passwordRules}
            autoFocus
            isRequired
            control={control}
            error={errors.password}
            onSubmitEditing={submitForm}
          />

          <FormInput
            label="Confirm your password"
            name="passwordConfirm"
            placeholder="Confirm Password"
            type="password"
            rules={confirmPasswordRules}
            isRequired
            control={control}
            error={errors.passwordConfirm}
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}

          {signupErrorType !== undefined && (
            <SignupErrorModal errorType={signupErrorType} onClose={onErrorClose} />
          )}
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-signup-password-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
