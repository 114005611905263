import { defaultTo } from 'lodash';
import { Platform } from 'react-native';
import { AuthMethod, SamlUserApp, useAuthInstructionsForEmailLazyQuery } from '../../../graphQL';

export enum ErrorTypesUserAuth {
  Failed = 'failed',
  NoSingleSignOn = 'no-single-sign-on',
}

type AuthError = {
  type: ErrorTypesUserAuth;
  message: string;
};
type ResponseArgs = {
  firstLogin: boolean;
  orgLabel: string;
  samlEntryPoint: string;
};

type UserAuthProps = {
  onCompleted: (args: ResponseArgs) => void;
  onError: (error: AuthError) => void;
};
type AuthProps = {
  email: string;
};

type AuthUserReturn = [(authData: AuthProps) => Promise<void>, { loading: boolean }];

export function useOldUserAuth({ onCompleted, onError }: UserAuthProps): AuthUserReturn {
  const [getAuthInstructions, { loading }] = useAuthInstructionsForEmailLazyQuery({
    onCompleted: ({ authInstructionsForEmail }) => {
      const { authMethod, organizationAbbreviation, hasPreviousSSOLogin } =
        authInstructionsForEmail;

      const samlEntryPoint = defaultTo(authInstructionsForEmail.samlEntryPoint, '');
      if (authMethod !== AuthMethod.Saml || !samlEntryPoint) {
        onError({
          type: ErrorTypesUserAuth.NoSingleSignOn,
          message: 'Your school is not currently supported.',
        });
        return;
      }

      onCompleted({
        firstLogin: hasPreviousSSOLogin !== true,
        orgLabel: defaultTo(organizationAbbreviation, ''),
        samlEntryPoint,
      });
    },
    onError: authError => {
      onError({
        type: ErrorTypesUserAuth.Failed,
        message: authError.message,
      });
    },
  });

  const authorizeUser = async ({ email }: AuthProps): Promise<void> => {
    const app = Platform.select({
      web: SamlUserApp.Hub,
      ios: SamlUserApp.HubiOs,
      android: SamlUserApp.HubAndroid,
      default: SamlUserApp.Hub,
    });
    await getAuthInstructions({ variables: { email, app } });
  };

  return [authorizeUser, { loading }];
}
