import { ApolloError } from '@apollo/client';
import {
  CoachingGoalsAssessmentQuery,
  useCoachingGoalsAssessmentQuery,
  useCoachingWellnessAssessmentQuery,
} from '../../../graphQL';

export type CoachingAssessment = 'goals' | 'wellness';
type CoachingAssessmentEnum = {
  [p in CoachingAssessment]: p;
};

export const coachingAssessmentTypes: CoachingAssessmentEnum = {
  goals: 'goals',
  wellness: 'wellness',
};

type UseCoachingAssessmentReturn = {
  assessments?: CoachingGoalsAssessmentQuery['coachingGoalsAssessment'];
  loading: boolean;
  error?: ApolloError | Error;
  type?: CoachingAssessment;
};

export const useCoachingAssessment = ({
  assessmentType,
}: {
  assessmentType?: string;
}): UseCoachingAssessmentReturn => {
  const {
    data: goalsData,
    loading: goalsLoading,
    error: goalsError,
  } = useCoachingGoalsAssessmentQuery({
    skip: assessmentType !== coachingAssessmentTypes.goals,
  });

  const {
    data: wellnessData,
    loading: wellnessLoading,
    error: wellnessError,
  } = useCoachingWellnessAssessmentQuery({
    skip: assessmentType !== coachingAssessmentTypes.wellness,
  });

  if (assessmentType === coachingAssessmentTypes.goals) {
    return {
      assessments: goalsData?.coachingGoalsAssessment,
      loading: goalsLoading,
      error: goalsError,
      type: coachingAssessmentTypes.goals,
    };
  }

  if (assessmentType === coachingAssessmentTypes.wellness) {
    return {
      assessments: wellnessData?.coachingWellnessAssessment,
      loading: wellnessLoading,
      error: wellnessError,
      type: coachingAssessmentTypes.wellness,
    };
  }

  return {
    loading: false,
    error: new Error('Invalid assessment type.'),
  };
};
