import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Skeleton, Text } from '../../components/core';
import { IconClockFour } from '../../components/icons/IconClockFour';
import { IconMapPin } from '../../components/icons/IconMapPin';
import { PageContent, PageCrisis, PageFooter } from '../../components/page';
import { useScrollContext } from '../../contexts/scrollContext';
import { useOnboardingUnderageErrorQuery } from '../../graphQL';
import { getRoute } from '../../routes';
import { getStylesheet } from '../../styles';

export function OnboardingUnderageErrorPage(): JSX.Element {
  const navigate = useNavigate();
  const { scrollToTop } = useScrollContext();
  const { data: centerData, loading: loadingCenterInfo } = useOnboardingUnderageErrorQuery();

  useEffect(() => {
    scrollToTop();
  }, []);

  const { departmentName, departmentAddress, departmentHours } =
    centerData?.currentHubUser.organizationCounselingCenter ?? {};

  const centerName = departmentName ?? '';
  const centerAddress = departmentAddress ?? '';
  const centerHours = departmentHours ?? '';
  const hasCenterInfo = Boolean(centerName || centerAddress || centerHours);

  const onContinuePress = (): void => {
    navigate(getRoute('logout', {}));
  };

  return (
    <PageContent {...styles.page} pageSize="medium" testID="onboarding-underage-error-page">
      <Layout.VStack {...styles.stack} space={4}>
        <Heading.h3>
          We’re sorry, but you don’t meet the eligibility criteria for the Care Hub.
        </Heading.h3>

        <Text.para>Due to legal considerations, Mantra is unable to serve minors.</Text.para>

        <Text.para>Please contact your school’s counseling center for support.</Text.para>

        {loadingCenterInfo && <Skeleton height={40} />}

        {hasCenterInfo && !loadingCenterInfo && (
          <Layout.VStack {...styles.counseling} space={4}>
            {centerName && <Heading.h4>{centerName}</Heading.h4>}

            {centerAddress && (
              <Layout.HStack space={4}>
                <IconMapPin size={4} />
                <Text.para>{centerAddress}</Text.para>
              </Layout.HStack>
            )}

            {centerHours && (
              <Layout.HStack space={4}>
                <IconClockFour size={4} />
                <Text.para>{centerHours}</Text.para>
              </Layout.HStack>
            )}
          </Layout.VStack>
        )}

        <PageCrisis />

        <Button.primaryLarge onPress={onContinuePress} testID="button-underage-error-i-understand">
          I understand
        </Button.primaryLarge>
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
}

const styles = getStylesheet({
  page: {
    height: '100%',
  },

  stack: {
    alignItems: 'stretch',
    display: 'flex',
  },

  counseling: {
    backgroundColor: 'secondary.200',
    padding: 4,
  },
});
