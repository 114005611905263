import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/link-error';
import { createClient } from 'graphql-ws';
import { compact } from 'lodash';
import { Platform } from 'react-native';
import { config } from '../config';
import { Location, NavigateFunction } from '../routes';
import { getLoginUrl } from '../utils/authentication';
import { getMobileToken } from '../utils/authenticationStore';
import { GraphqlException } from '../utils/graphql';
import sentry from '../utils/sentry';

export type SetupApolloResponse = ApolloClient<NormalizedCacheObject>;

type ApolloRouting = { location: Location; navigate: NavigateFunction };
export type SetupApolloProps = ApolloRouting;

const mobileAuthHeader = setContext(async (request, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getMobileToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

export const generateApolloLinks = ({ location, navigate }: ApolloRouting): ApolloLink => {
  const errorHandlerLink = onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach(async gqlError => {
      const exception = gqlError.extensions?.exception as GraphqlException | undefined;

      if (exception?.code === 'INVALID_AUTHENTICATION') {
        // If the user is not logged in or the token is bad, force them to the
        // login page.
        // Force logout?

        navigate(getLoginUrl(location));
      }

      // eslint-disable-next-line no-console
      console.log(gqlError);
    });
  });

  const httpLink = new HttpLink({ uri: `${config.apiUrl}/graphql`, credentials: 'include' });

  // WS Link
  if (config.webSocketUrl === '') {
    sentry.captureException('Missing webSocketUrl in config');
  }

  const wsBaseClientOptions = {
    url: `${config.webSocketUrl}`,
  };

  const nativeWsClientOptions = {
    ...wsBaseClientOptions,
    connectionParams: async () => {
      const token = await getMobileToken();
      return {
        authToken: token,
      };
    },
  };

  const wsClientOptions = Platform.select({
    web: wsBaseClientOptions,
    default: wsBaseClientOptions,
    android: nativeWsClientOptions,
    ios: nativeWsClientOptions,
  });

  const wsLink = new GraphQLWsLink(createClient(wsClientOptions));

  // https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const authLink: ApolloLink | undefined = Platform.select({
    web: undefined,
    default: undefined,
    android: mobileAuthHeader,
    ios: mobileAuthHeader,
  });

  return from(compact([authLink, errorHandlerLink, splitLink]));
};

export const setupApollo = ({ ...linkProps }: SetupApolloProps): SetupApolloResponse => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: generateApolloLinks(linkProps),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
      watchQuery: { fetchPolicy: 'no-cache' },
    },
  });
};
