import { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Link, Text } from '../../../components/core';
import { IconFrown } from '../../../components/icons/IconFrown';
import { IconGlobe } from '../../../components/icons/IconGlobe';
import { PageContent, PageCrisis } from '../../../components/page';
import { getRoute } from '../../../routes';
import { ErrorAssistance } from './ErrorAssistance';

const AuthErrorCatchAll = (): JSX.Element => {
  const navigate = useNavigate();

  const onTryAgainPress = (): void => {
    navigate(getRoute('login', {}));
  };

  return (
    <Layout.VStack space={5}>
      <Layout.Center>
        <IconFrown accessibilityHidden size={6} />
      </Layout.Center>

      <Layout.VStack space={2}>
        <Heading.h3>We’re having trouble logging you in</Heading.h3>

        <Button.tertiaryMedium
          alignSelf="flex-start"
          onPress={onTryAgainPress}
          paddingLeft={0}
          testID="button-sso-error-try-again"
        >
          Please try again.
        </Button.tertiaryMedium>

        <ErrorAssistance />
      </Layout.VStack>
    </Layout.VStack>
  );
};

const AuthErrorDisabled = (): JSX.Element => (
  <Layout.VStack space={5}>
    <Heading.h3>Your account has been disabled due to cancellation or discharge.</Heading.h3>

    <Text.para>
      If you have questions or believe this is an error, please email{' '}
      <Link.para to="mailto:hi@mantrahealth.com">hi@mantrahealth.com</Link.para>.
    </Text.para>
  </Layout.VStack>
);

const AuthErrorIsIneligible = (): JSX.Element => (
  <Layout.VStack space={5}>
    <Heading.h3>We partner with your institution, but we can’t verify your eligibility</Heading.h3>

    <Text.para>
      Based on the email address you entered, Mantra partners with your school to provide care for
      currently enrolled students.
    </Text.para>

    <ErrorAssistance />
  </Layout.VStack>
);

const AuthErrorReferralRequired = (): JSX.Element => (
  <Layout.VStack space={5}>
    <Heading.h3>Campus referral required</Heading.h3>

    <Text.para>
      To sign up for Mantra services, your university requires a referral from an authorized campus
      staff member.
    </Text.para>

    <Text.para bold>
      Contact your school counseling center, or refer to your institution's mental health resources
      on their website.
    </Text.para>

    <ErrorAssistance />
  </Layout.VStack>
);

const AuthErrorToPatientPortal = (): JSX.Element => (
  <Layout.VStack space={5}>
    <Layout.Center>
      <IconGlobe accessibilityHidden size={6} />
    </Layout.Center>

    <Heading.h3>You’ve been redirected to the Web version of the Patient Portal.</Heading.h3>

    <Text.para>
      The Mantra Health mobile app does not yet support our therapy and psychiatry experience, so
      we’ve redirected you to your account within your mobile browser.
    </Text.para>

    <ErrorAssistance />
  </Layout.VStack>
);

const errorMap = {
  catchAll: AuthErrorCatchAll,
  disabled: AuthErrorDisabled,
  isIneligible: AuthErrorIsIneligible,
  refRequired: AuthErrorReferralRequired,
  toPatientPortal: AuthErrorToPatientPortal,
};

export type AuthErrorType = keyof typeof errorMap;

const ssoErrorNumberToTypeMap: Record<number, AuthErrorType> = {
  1: 'catchAll',
  3: 'refRequired',
  4: 'isIneligible',
  5: 'disabled',
};

export const parseSsoErrorNumberToType = (errorNumber: number): AuthErrorType => {
  return ssoErrorNumberToTypeMap[errorNumber] ?? 'catchAll';
};

type AuthErrorArgs = {
  errorType: AuthErrorType;
};

export const AuthError = ({ errorType }: AuthErrorArgs): JSX.Element => {
  const ErrorComponent = errorMap[errorType];

  return (
    <PageContent testID="login-response-error-page">
      <Layout.VStack space={7}>
        <ErrorComponent />

        <PageCrisis />
      </Layout.VStack>
    </PageContent>
  );
};
