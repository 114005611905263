import { findKey, isEmpty } from 'lodash';
import { generatePath, ParamParseKey } from 'react-router-dom';
import { AllowedPortal, ANY, Portals } from './content/portal/portals';
import { matchRoutes, useLocation } from './router';

export * from './router';
export { useExternalNavigate } from './utils/useExternalNavigate';

export type RouteOptions =
  | {
      public?: boolean;
    }
  | undefined;
export type RouteType = Readonly<[string, AllowedPortal, RouteOptions?]>;

export const routes = {
  accountSettings: ['/account-settings', Portals.Hub],
  campusResources: ['/campus-resources', Portals.Hub],
  coaching: ['/coaching', Portals.Coaching],
  coachingAssessment: ['/coaching/assess/:assessmentType/:slide', Portals.Coaching],
  coachingChat: ['/coaching/chat', Portals.Coaching],
  coachingOnboarding: ['/coaching/onboard', Portals.Coaching],
  crisisResources: ['/crisis-resources', Portals.Hub],
  deepLinkPage: ['/deep-link', Portals.Hub, { public: true }],
  findCareAssessments: ['/find-care/assess/:assessmentType/:slide', Portals.Hub],
  findCareConfirmInfo: ['/find-care/confirm-info', Portals.Hub],
  findCareCrisisSupport: ['/find-care/crisis-support/:slide', Portals.Hub],
  findCareDemographics: ['/find-care/demographics', Portals.Hub],
  findCarePreQuantitative: ['/find-care/pre-quantitative', Portals.Hub],
  findCarePreTriage: ['/find-care/pre-triage', Portals.Hub],
  findCareStartCare: ['/find-care/start', Portals.Hub],
  findCareRecommendation: ['/find-care/recommendation', Portals.Hub],
  findCareReviewAnswers: ['/find-care/review-answers', Portals.Hub],
  forwardOnPortal: ['/forward/*', Portals.Hub],
  home: ['/home', Portals.Hub],
  login: ['/login', Portals.Hub, { public: true }],
  loginNew: ['/login/new', Portals.Hub, { public: true }],
  loginActivationResend: ['/login/activation/resend', Portals.Hub, { public: true }],
  loginFromPortal: ['/login/callback', Portals.Hub, { public: true }],
  loginResetPassword: ['/login/reset', Portals.Hub, { public: true }],
  loginNewPassword: ['/login/reset/new', Portals.Hub, { public: true }],
  loginSsoResponse: ['/login/sso/response', Portals.Hub, { public: true }],
  logout: ['/logout', Portals.Hub, { public: true }],
  module: ['/m/:moduleId', Portals.Skills],
  notFound: ['*', Portals.Hub, { public: true }],
  notFoundBlocked: ['*', Portals.LocationBlocked, { public: true }],
  onboarding: ['/onboarding', Portals.Hub],
  onboardingMinorBlocked: ['/onboarding/minor', Portals.Hub],
  onDemandConfirmInfo: ['/on-demand/confirm-info', Portals.Odes],
  onDemandHome: ['/on-demand', Portals.Odes],
  onDemandJoinCall: ['/on-demand/join-call', Portals.Odes],
  onDemandInQueue: ['/on-demand/in-queue', Portals.Odes],
  onDemandPreEncounterForm: ['/on-demand/pre-encounter-form', Portals.Odes],
  patientPortal: ['/patient-portal', ANY(Portals.Therapy, Portals.Psychiatry)],
  reflection: ['/reflection/:reflectionId', Portals.Skills],
  reflections: ['/reflections', Portals.Skills],
  reschedule: ['/reschedule/:appointmentId', ANY(Portals.Coaching, Portals.Loca)],
  selfCareHome: ['/self-care', Portals.Skills],
  selfCareQuiz: ['/self-care/quiz/:slide', Portals.Skills],
  signup: ['/signup', Portals.Hub, { public: true }],
  signupFinish: ['/signup/finish', Portals.Hub, { public: true }],
  skill: ['/m/:moduleId/skill/:skillId/:slide', Portals.Skills],
  skillPathway: ['/skill/pathway', Portals.Skills],
  skillPathwaySection: ['/skill/pathway/m/:moduleId/s/:sectionId', Portals.Skills],
  start: ['/', Portals.Hub, { public: true }],
  therapy: ['/therapy', Portals.NonLocaTherapyGateway],
  tour: ['/tour/:slide', Portals.Loca],
} as const;

export type RouteKeys = keyof typeof routes;

type RoutePaths = {
  [a in keyof typeof routes]: (typeof routes)[a][0];
};

type RouteParamNames<RouteName extends RouteKeys> = ParamParseKey<RoutePaths[RouteName]>;
export type RouteParams<RouteName extends RouteKeys> = {
  [key in RouteParamNames<RouteName>]: string;
};

export const parseRouteName = (location: ReturnType<typeof useLocation>): RouteKeys => {
  const searchableRoutes = Object.values(routes).map(([routePath]) => ({ path: routePath }));

  const matches = matchRoutes(searchableRoutes, location);

  const matchedRoute = matches?.[0]?.route;
  if (!matchedRoute) {
    return 'notFound';
  }

  const matchedRouteName =
    findKey(routes, ([routePath]) => routePath === matchedRoute.path) ?? 'notFound';

  return matchedRouteName as RouteKeys;
};

export const useCurrentRouteName = (): RouteKeys => {
  const location = useLocation();

  return parseRouteName(location);
};

export const useCurrentRouteSearch = (): Record<string, string> => {
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  // Convert the search entries iterator to an object.
  return Object.fromEntries(search.entries());
};

export const getRoute = <
  RouteName extends Exclude<RouteKeys, 'notFound'>,
  RouteParamValues extends RouteParams<RouteName>,
  RoutePath extends RoutePaths[RouteName],
>(
  routeName: RouteName,
  paramValues: RouteParamValues,
  searchParamValues?: Record<string, string>,
): string => {
  const routePath = routes[routeName][0] as RoutePath;
  const fullPath = generatePath(routePath, paramValues);

  if (searchParamValues) {
    const searchParams = new URLSearchParams(searchParamValues);
    return `${fullPath}?${searchParams.toString()}`;
  }

  return fullPath;
};

const isLocationPublic = (location: ReturnType<typeof useLocation>): boolean => {
  const searchableRoutes = Object.values(routes).map(([routePath]) => ({ path: routePath }));

  const matches = matchRoutes(searchableRoutes, location);

  const matchedRoute = matches?.[0]?.route;
  if (!matchedRoute) {
    return true;
  }

  const matchedRouteName =
    findKey(routes, ([routePath]) => routePath === matchedRoute.path) ?? 'notFound';

  const routeOptions = routes[matchedRouteName as RouteKeys][2];

  return routeOptions?.public ?? false;
};

export const useIsCurrentRoutePublic = (): boolean => {
  const location = useLocation();

  return isLocationPublic(location);
};

export const appendSearchToRoute = (
  fullPath: string,
  newSearchParams: Record<string, string>,
): string => {
  if (isEmpty(newSearchParams)) {
    return fullPath;
  }

  const searchParams = new URLSearchParams(newSearchParams);

  // Does not support routes with hashes...
  const appender = fullPath.includes('?') ? '&' : '?';
  return `${fullPath}${appender}${searchParams.toString()}`;
};
