import * as ExpoLinking from 'expo-linking';
import React, { useEffect } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconArrowUpRight } from '../../components/icons/IconArrowUpRight';
import { useSearchParams } from '../../routes';
import { getStylesheet } from '../../styles';
import Sentry from '../../utils/sentry';

export function DeepLinkPage(): JSX.Element {
  const [searchParamsOrig] = useSearchParams();
  const searchParams = new URLSearchParams(searchParamsOrig);
  const androidDeepLink = searchParams.get('androidHubDeepLink') ?? '';
  searchParams.delete('androidHubDeepLink');
  const preppedLink = `${androidDeepLink}/?${searchParams.toString()}`;

  useEffect(() => {
    ExpoLinking.canOpenURL(preppedLink)
      .then(async result => {
        if (result) {
          await ExpoLinking.openURL(preppedLink);
        }
      })
      .catch(error => Sentry.captureException(error));
  }, [preppedLink]);

  const goToApp = async (): Promise<void> => {
    await ExpoLinking.openURL(preppedLink);
  };

  return (
    <Layout.VStack {...styles.stack} space={8}>
      <Heading.h3>Authentication Successful</Heading.h3>
      <Text.para>Return to the app to complete your login.</Text.para>
      <Button.primaryMedium
        testID="button-deep-link-open-app"
        rightIcon={<IconArrowUpRight accessibilityHidden />}
        onPress={goToApp}
      >
        Go to App
      </Button.primaryMedium>
    </Layout.VStack>
  );
}

const styles = getStylesheet({
  page: {
    height: '100%',
    marginTop: 16,
    position: 'relative',
  },

  stack: {
    alignItems: 'center',
    display: 'flex',
  },
});
