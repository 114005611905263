import { useEffect, useMemo, useState } from 'react';
import { Heading, Layout, Progress } from '../../../components/core';
import { ConfirmLeaveModal } from '../../../components/modals/ConfirmLeaveModal';
import { PageContent, PageTopBar } from '../../../components/page';
import { useScrollContext } from '../../../contexts/scrollContext';
import { getRoute, routes, useLocation, useNavigate, useParams } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { useFindCareSlideContext } from '../context/findCareSlideContext';

const findCareRoutes = [
  { route: routes.findCareConfirmInfo[0], params: undefined, pages: 1 },
  { route: routes.findCareDemographics[0], params: undefined, pages: 1 },
  { route: routes.findCarePreTriage[0], params: undefined, pages: 1 },
  {
    route: routes.findCareAssessments[0],
    params: { assessmentType: 'mental-health-history' },
    pages: 8,
  },
  { route: routes.findCarePreQuantitative[0], params: undefined, pages: 1 },
  { route: routes.findCareAssessments[0], params: { assessmentType: 'phq' }, pages: 8 },
  { route: routes.findCareAssessments[0], params: { assessmentType: 'gad' }, pages: 7 },
  { route: routes.findCareReviewAnswers[0], params: undefined, pages: 1 },
] as const;

type FindCarePageWrapperProps = {
  children: JSX.Element;
  testID: string;
  showProgress?: boolean;
  onBackPress?: () => void;
  showGap?: boolean;
};

export const FindCarePageWrapper = ({
  children,
  testID,
  showProgress = true,
  onBackPress,
  showGap = true,
}: FindCarePageWrapperProps): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { assessmentType } = useParams();

  const { triggerPrevSlide, slideNumber, slideCount } = useFindCareSlideContext();
  const { scrollToTop } = useScrollContext();

  useEffect(() => {
    // When the page loads, make sure to scroll to the top.
    scrollToTop();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const getCurrentPageIndex = (): number => {
    return findCareRoutes.findIndex(route => {
      if (route.params === undefined) {
        return route.route === pathname;
      }

      return route.params.assessmentType === assessmentType;
    });
  };

  const goBack = (): void => {
    if (onBackPress !== undefined) {
      onBackPress();
      return;
    }

    if (slideCount > 0 && slideNumber > 1) {
      triggerPrevSlide();
      return;
    }

    const currentPageIdx = getCurrentPageIndex();
    const currentRoute = findCareRoutes[currentPageIdx];
    const previousPage = findCareRoutes[currentPageIdx - 1];
    const firstGADPage = getRoute('findCareAssessments', {
      assessmentType: 'gad',
      slide: '1',
    });

    // TODO: Find a dynamic way to handle this instead of hardcoding the slide numbers
    if (currentRoute?.route !== undefined && previousPage?.route !== undefined) {
      switch (currentRoute.route) {
        case routes.findCarePreQuantitative[0]: {
          navigate(
            getRoute('findCareAssessments', {
              assessmentType: 'mental-health-history',
              slide: '8',
            }),
          );
          break;
        }

        case routes.findCareReviewAnswers[0]: {
          navigate(
            getRoute('findCareAssessments', {
              assessmentType: 'gad',
              slide: '7',
            }),
          );
          break;
        }

        case firstGADPage: {
          navigate(
            getRoute('findCareAssessments', {
              assessmentType: 'phq',
              slide: '8',
            }),
          );
          break;
        }

        default: {
          navigate(previousPage.route, {});
        }
      }
    }
  };

  const goHome = (): void => {
    navigate(getRoute('home', {}));
  };

  const getProgress = useMemo(() => {
    const currentPageIdx = getCurrentPageIndex();
    const previousPages = getTotalPages(currentPageIdx);
    const totalPages = getTotalPages();
    return ((previousPages + 1) / totalPages) * 100;
  }, [pathname]);

  const containerStyle = showGap
    ? { ...styles.container, ...styles.containerGap }
    : styles.container;

  return (
    <PageContent {...containerStyle} testID={testID}>
      <Layout.Box>
        <PageTopBar
          backAccessibilityLabel="Navigate to the previous page."
          onBackPress={goBack}
          onClosePress={() => setShowModal(true)}
        >
          <Heading.h5 center paddingY={3}>
            Find Care
          </Heading.h5>
        </PageTopBar>

        {showProgress && (
          <Progress
            {...styles.progress}
            accessibilityLabel="Find care progress."
            variant="skill"
            value={getProgress}
          />
        )}

        {showModal && <ConfirmLeaveModal onClose={() => setShowModal(false)} onConfirm={goHome} />}
      </Layout.Box>
      {children}
    </PageContent>
  );
};

const styles = getStylesheet({
  container: {
    flex: 1,
    padding: 1,
  },

  containerGap: {
    gap: 8,
  },

  progress: {
    width: '70%',
    alignSelf: 'center',
    marginBottom: 4,
  },
});

const getTotalPages = (currentPageIndex?: number | undefined): number => {
  const total = findCareRoutes.reduce((acc, route, index) => {
    if (
      currentPageIndex === undefined ||
      (currentPageIndex !== undefined && index < currentPageIndex)
    ) {
      return acc + route.pages;
    }
    return acc;
  }, 0);

  return total;
};
