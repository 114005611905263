import { Platform } from 'react-native';
import { SamlUserApp } from '../../../graphQL';

export enum ErrorTypesLoginUser {
  Failed = 'failed',
  NoSingleSignOn = 'no-single-sign-on',
  RateLimit = 'rate-limit',
}

type ResponseArgs = {
  // If true, the user has been logged in (using their password).
  loginSuccess: boolean;
  // If true, the user needs to enter a password.
  passwordRequired: boolean;
  // A string means to redirect them to this sso entry point, undefined means they logged in (or need to) using the password.
  samlEntryPoint: string | undefined;
};
type LoginError = {
  type: ErrorTypesLoginUser;
  message: string;
};

type LoginUserProps = {
  email: string;
  password?: string;
};

type UseLoginUserProps = {
  onCompleted: (args: ResponseArgs) => void;
  onError: (error: LoginError) => void;
};
type UseLoginUserReturn = [(loginData: LoginUserProps) => Promise<void>, { loading: boolean }];

export const useLoginUser = ({ onCompleted, onError }: UseLoginUserProps): UseLoginUserReturn => {
  const appType = Platform.select({
    web: SamlUserApp.Hub,
    ios: SamlUserApp.HubiOs,
    android: SamlUserApp.HubAndroid,
    default: SamlUserApp.Hub,
  });

  const loading = false;
  const hubLogin = (props: {
    variables: { app: string; email: string; password?: string };
  }): void => {
    if (props.variables.password === undefined || props.variables.password === '') {
      onCompleted({
        loginSuccess: false,
        passwordRequired: true,
        samlEntryPoint: undefined,
      });
      return;
    }

    if (props.variables.password.length < 8) {
      // This is just a demo of onError, don't hard code password length checks.
      onError({ type: ErrorTypesLoginUser.Failed, message: 'Insufficient password length.' });
      return;
    }

    onCompleted({
      loginSuccess: true,
      passwordRequired: false,
      samlEntryPoint: undefined,
    });
  };
  // const [hubLogin { loading }] = useHubLoginLazyQuery({
  //   onCompleted: ({ hubLogin }) => {
  //     const { loginSuccess, passwordRequired, samlEntryPoint } = hubLogin;
  //
  //     if (loginSuccess) {
  //       onCompleted({
  //         loginSuccess: true,
  //         passwordRequired: false,
  //         samlEntryPoint: undefined,
  //       });
  //       return;
  //     }
  //
  //     if (passwordRequired) {
  //       onCompleted({
  //         loginSuccess: false,
  //         passwordRequired: true,
  //         samlEntryPoint: undefined,
  //       });
  //       return;
  //     }
  //
  //     // Saml
  //     if (samlEntryPoint === undefined || samlEntryPoint === '') {
  //       onError({
  //         type: ErrorTypesLoginUser.NoSingleSignOn,
  //         message: 'Your school is not currently supported.',
  //       });
  //     }
  //
  //     onCompleted({
  //       loginSuccess: false,
  //       passwordRequired: false,
  //       samlEntryPoint,
  //     });
  //   },
  //   onError: authError => {
  //     if (isRateLimited(authError)) {
  //       onError({
  //         type: ErrorTypesLoginUser.RateLimit,
  //         message:
  //           'Your account has been locked due to too many unsuccessful login attempts. Please wait 15 minutes or reset your password to log in.',
  //       });
  //       return;
  //     }
  //
  //     onError({
  //       type: ErrorTypesLoginUser.Failed,
  //       message: authError.message,
  //     });
  //   },
  // });

  const loginUser = async ({ email, password }: LoginUserProps): Promise<void> => {
    await hubLogin({ variables: { app: appType, email, password } });
  };

  return [loginUser, { loading }];
};
