import { some } from 'lodash';
import { JSX, useState } from 'react';
import { Task, TaskList } from '../../../components/core';
import {
  JoinAppointmentWidget,
  UpcomingAppointment,
} from '../../../content/appointment/components/JoinAppointmentWidget';
import { useCoachingIntakeProgressQuery } from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';
import { coachingAssessmentTypes } from '../../coachingAssessment/hooks/useCoachingAssessment';
import { CoachingTasksWarningWidget } from './CoachingTasksWarningWidget';

let renderCount = 0;

export const CoachingAppointmentTaskCollection = (): JSX.Element | null => {
  const navigate = useNavigate();

  const [upcomingAppointment, setUpcomingAppointment] = useState<UpcomingAppointment | undefined>();
  const { data: coachingIntakeProgressData, loading: intakeProgressLoading } =
    useCoachingIntakeProgressQuery();

  if (intakeProgressLoading) {
    return null;
  }

  const coachingIntakeProgress = coachingIntakeProgressData?.coachingIntakeProgress;

  if (!coachingIntakeProgress) {
    return null;
  }

  const completed = {
    goals: coachingIntakeProgress?.setGoalsCompleted,
    wellness: coachingIntakeProgress?.wellnessAssessmentCompleted,
  };
  const hasUncompletedTasks = some(completed, taskCompleted => taskCompleted === false);

  const tasksWarning = hasUncompletedTasks ? (
    <CoachingTasksWarningWidget appointmentData={upcomingAppointment} key="tasks-warning" />
  ) : null;

  const taskList = coachingIntakeProgress && (
    <TaskList
      headerText="Getting Started"
      description="Complete these before your kickoff call."
      key="coaching-tasks"
    >
      <Task
        completed={completed.goals ?? true}
        timeToComplete="3 min"
        title="Set Your Goals"
        onPress={() => {
          navigate(
            getRoute('coachingAssessment', {
              assessmentType: coachingAssessmentTypes.goals,
              slide: '1',
            }),
          );
        }}
      />

      <Task
        completed={completed.wellness ?? true}
        timeToComplete="3 min"
        title="Wellness Assessment"
        onPress={() => {
          navigate(
            getRoute('coachingAssessment', {
              assessmentType: coachingAssessmentTypes.wellness,
              slide: '1',
            }),
          );
        }}
      />
    </TaskList>
  );

  console.log('Task List is: ', taskList);
  console.log('renderCount is: ', renderCount);
  renderCount++;

  return (
    <>
      {tasksWarning}
      {taskList}
      <JoinAppointmentWidget
        key="join-appointment"
        onAppointmentLoad={setUpcomingAppointment}
        blockEarlyJoining={hasUncompletedTasks}
      />
    </>
  );
};
