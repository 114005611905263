import { ApolloProvider } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { AppRoutes } from './AppRoutes';
import { BreakpointProvider } from './contexts/breakpointContext';
import { HeapProvider } from './contexts/heapContext';
import { PortalProvider } from './contexts/portalContext';
import { ScrollProvider } from './contexts/scrollContext';
import { setupAppPlugins, NativeBaseProvider, PrismicProvider } from './plugins';
import { MobileAppAccessories } from './plugins/accessories/MobileAppAccessories';
import { generateApolloLinks } from './plugins/apolloSetup';
import { PushNotificationsHandler } from './plugins/pushNotifications/PushNotificationsHandler';
import { useLocation, useNavigate } from './routes';

export const AppPlugins = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const { apolloClient } = useMemo(() => {
    return setupAppPlugins({
      location,
      navigate,
    });
  }, []);

  useEffect(() => {
    apolloClient.setLink(generateApolloLinks({ location, navigate }));
  }, [location, navigate]);

  return (
    <ApolloProvider client={apolloClient}>
      <NativeBaseProvider>
        <BreakpointProvider>
          <PrismicProvider>
            <HeapProvider>
              <PortalProvider>
                <GestureHandlerRootView style={{ flex: 1 }}>
                  <ScrollProvider>
                    <PushNotificationsHandler />
                    <MobileAppAccessories />
                    <AppRoutes />
                  </ScrollProvider>
                </GestureHandlerRootView>
              </PortalProvider>
            </HeapProvider>
          </PrismicProvider>
        </BreakpointProvider>
      </NativeBaseProvider>
    </ApolloProvider>
  );
};
