import { useEffect } from 'react';
import { useOnboardedUserLazyQuery } from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';

export const NewUserRedirect = (): null => {
  const navigate = useNavigate();
  const [fetchOnboardedUser] = useOnboardedUserLazyQuery();

  const check = async (): Promise<void> => {
    const { data } = await fetchOnboardedUser();
    if (!data) {
      return;
    }

    if (!data.currentHubUser.hasHubOnboarded) {
      navigate(getRoute('onboarding', {}), { replace: true });
    }
  };

  useEffect(() => {
    void check();
  }, []);

  return null;
};
