import { Layout, Link, Text } from '../../../components/core';
import { IconInfo } from '../../../components/icons/IconInfo';
import { getRoute } from '../../../routes';
import { getStylesheet } from '../../../styles';

export const CoachChatCrisisWarning = (): JSX.Element => {
  return (
    <Layout.HStack {...styles.feedback} space={2}>
      <IconInfo accessibilityHidden size={4} minHeight={4} minWidth={4} />
      <Text.caption>
        In the case of an emergency, call 911 or contact an available{' '}
        <Link.caption to={getRoute('crisisResources', {})}>campus emergency resource</Link.caption>.
        Mantra cannot guarantee a response time.
      </Text.caption>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  feedback: {
    alignItems: 'center',
    paddingY: 4,
  },
});
